/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
export default function Dashboard() {
  const [userData, setUserData] = useState();
  const [DataCount, setDataCount] = useState([34, 45, 67, 89, 23]);
  const [UserGraphData, setUserGraphData] = useState([34, 45, 67, 89, 23]);
  const [OrderGraphData, setOrderGraphData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <div>
      <div className="">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 mb-3">
                <h3 className="m-0 fs-30">Dashboard</h3>
              </div>
              {/* <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div> */}
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12  transparent">
                <div className="row"></div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="chartjs-size-monitor">
                      <div className="chartjs-size-monitor-expand">
                        <div className />
                      </div>
                      <div className="chartjs-size-monitor-shrink">
                        <div className />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="card-title">Users Graph</p>
                     
                    </div>
                    <p className="font-weight-500">
                      The total number of users within the date range. It is the
                      period time a user is actively engaged with your website,
                      page or app, etc
                    </p>
                    <div id="sales-legend" className="chartjs-legend mt-4 mb-4">
                      <ul className="1-legend">
                      
                        <li>
                          <span
                            style={{ backgroundColor: "rgb(75, 73, 172)" }}
                          />
                          Users
                        </li>
                      </ul>
                    </div>
                    <div className="App">
                      {UserGraphData ? (
                        <BarChart UserGraphData={UserGraphData} />
                      ) : (
                        <p>Loading data...</p>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-sm-7">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card card-bg" style={{ height: "150px" }}>
                      <h4 className="h3 p-3">Total Balance</h4>
                    </div>
                  </div>
                  <div className="col-sm-12  mt-4">
                    <div className="card card-bg " style={{ height: "150px" }}>
                      <h4 className="h3 p-3">Group Balance</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="card card-bg" style={{ height: "350px" }}></div>
              </div>
              <div className="col-12 mt-3">
                <div className="card card-bg" style={{ height: "180px" }}>
                  <h4 className="h3 p-3">Last Transactions</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
