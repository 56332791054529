import axios from "axios";
// import history from "../Helper/history";
// import { useNavigate  } from 'react-router-dom';

// Common get API
export const GetService = async (url, params) => {
  const token = localStorage.getItem("token");

  if (!url) {
    return;
  }

  const axiosConfig = {
    headers: {
      Accept: "application/json",
      Authorization: token,
    },
    ...params,
  };

  try {
    const response = await axios.get(url, axiosConfig);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      // history.push("/login");
      window.location.reload();
    }
    return error.response;
  }
};

// Common Post API for JSON post
export const PostService = async (url, data) => {
  // const history1 = useNavigate ();
  const token = localStorage.getItem("token");

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  try {
    const response = await axios.post(url, data, axiosConfig);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      // history.push("/login");
      window.location.reload();
    }
    return error.response;
  }
};

// Common Post API for FormData post
export const PostFormData = async (url, data) => {
  const token = localStorage.getItem("token");

  const axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  };

  try {
    const response = await axios.post(url, data, axiosConfig);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      // history.push("/login");
      window.location.reload();
    }
    return error.response;
  }
};

// Common Put API
export const PutService = async (url, data) => {
  const token = localStorage.getItem("token");

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  try {
    const response = await axios.put(url, data, axiosConfig);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      // history.push("/login");
      window.location.reload();
    }
    return error.response;
  }
};

// Common Put API for FormData
export const PutFormData = async (url, data) => {
  const token = localStorage.getItem("token");

  const axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  };

  try {
    const response = await axios.put(url, data, axiosConfig);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      // history.push("/login");
      window.location.reload();
    }
    return error.response;
  }
};

// Common Delete API
export const DeleteService = async (url, params) => {
  const token = localStorage.getItem("token");

  const axiosConfig = {
    headers: {
      Accept: "application/json",
      Authorization: token,
    },
    ...params,
  };

  try {
    const response = await axios.delete(url, axiosConfig);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      // history.push("/login");
      window.location.reload();
    }
    return error.response;
  }
};
