import React, { useState, useCallback, useEffect } from "react";
import { Droppable, Draggable } from "react-tiny-drag-drop";
import { PostService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import { toastEmmit } from "../../../../Helper/Toastr";
import "../Drag And Drop/DragAndDrop.css";

/**
 * Custom Drag and Drop Component for managing referent profile groups
 * @param {Object} props
 * @param {Array} props.referentProfileGroupDetails - Array of profiles with their groups
 * @param {Function} props.onGroupsUpdate - Callback function to update parent state
 */
export function CustomDragAndDrop({
  referentProfileGroupDetails,
  onGroupsUpdate,
}) {
  // Initialize state with prop value
  const [groupData, setGroupData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Update local state when prop changes
  useEffect(() => {
    if (referentProfileGroupDetails && referentProfileGroupDetails.length > 0) {
      setGroupData(JSON.parse(JSON.stringify(referentProfileGroupDetails)));
    }
  }, [referentProfileGroupDetails]);

  // Notify parent component of changes
  useEffect(() => {
    if (groupData && groupData.length > 0 && onGroupsUpdate) {
      onGroupsUpdate(groupData);
    }
  }, [groupData, onGroupsUpdate]);

  const handleDrop = useCallback(async (profileId, groupId) => {
    try {
      setIsLoading(true);

      if (!profileId || !groupId) {
        throw new Error("Invalid profile or group ID");
      }

      // Make API call first before updating UI
      const response = await PostService(
        `${API_URL.UPDATE_REFERENT_PROFILE_ID}`,
        {
          id: groupId,
          referentProfileId: profileId,
        }
      );

      if (!response) {
        throw new Error("Failed to update group assignment");
      }

      // Update state using functional update to avoid stale state issues
      setGroupData((prevData) => {
        const newData = JSON.parse(JSON.stringify(prevData)); // Deep copy

        let sourceProfile = null;
        let movedGroup = null;

        // Find source profile and group
        for (const profile of newData) {
          const foundGroup = profile.groups.find((g) => g.id === +groupId);
          if (foundGroup) {
            sourceProfile = profile;
            movedGroup = { ...foundGroup };
            break;
          }
        }

        if (!sourceProfile || !movedGroup) {
          console.error("Source profile or group not found");
          return prevData; // Return previous state if issue occurs
        }

        const targetProfile = newData.find((p) => p.id === +profileId);
        if (!targetProfile) {
          console.error("Target profile not found");
          return prevData;
        }

        if (sourceProfile.id === targetProfile.id) {
          console.warn("Cannot move group to the same profile");
          return prevData;
        }

        // Remove from source profile
        sourceProfile.groups = sourceProfile.groups.filter(
          (g) => g.id !== +groupId
        );

        // Add to target profile
        targetProfile.groups.push(movedGroup);

        // Force a state update by returning a new reference
        return [...newData];
      });

      toastEmmit("Group moved successfully", "success");
    } catch (error) {
      console.error("Error in handleDrop:", error);
      toastEmmit(
        error.message || "Failed to move group. Please try again.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const renderGroups = useCallback((groups) => {
    if (!groups || groups.length === 0) {
      return (
        <div className="text-center text-muted p-3">No groups available</div>
      );
    }

    return groups.map((group) => (
      <Draggable key={group.id} context="groups" dataKey={group.id}>
        <div className="Draggable drag-item">
          <div className="d-flex align-items-center">
            <div className="mr-3">
              <i className="fa fa-bars" style={{ color: "#666" }}></i>
            </div>
            <div className="mx-5">
              <i className="fa fa-wallet" style={{ color: "#666" }}></i>
            </div>
            <div className="mx-3 flex-grow-1">{group.name}</div>
          </div>
        </div>
      </Draggable>
    ));
  }, []);

  if (!groupData || groupData.length === 0) {
    return <div className="alert alert-info">No profile data available</div>;
  }

  return (
    <div className="CustomDragAndDrop">
      <div className="row mx-0">
        {groupData.map((profile) => (
          <div key={profile.id} className="col-12 p-0">
            <div className="card shadow-sm drag-card">
              <div className="card-body drag-section">
                <h4 className="d-flex align-items-center drop-container-title">
                  {profile.profile_name}
                  {profile.groups && (
                    <span className="badge bg-dark text-light ml-2">
                      {profile.groups.length}
                    </span>
                  )}
                </h4>
                <Droppable
                  context="groups"
                  onDrop={(groupId) => handleDrop(profile.id, groupId)}
                >
                  <div
                    className={`Droppable drop-container ${
                      isLoading ? "opacity-50" : ""
                    }`}
                  >
                    {renderGroups(profile.groups)}
                  </div>
                </Droppable>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
