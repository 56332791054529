/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { GetService, PostService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import "../Slip/Slip.css";
import { useFormik } from "formik";
import Accordion from "react-bootstrap/Accordion";
import { format, parseISO } from "date-fns";
import {
  showErrorAlert,
  showWarningAlert,
} from "../../../../Helper/SweetAlert";
import TennisIcon from "../../../../assets/img/tennis.png";
import { getCurrencyListing } from "../../../../Helper/utils";
import { toast } from "react-toastify";

export default function CreateSlip() {
  let navigate = useNavigate();
  const eventtypeoption = [
    { name: "Single/Multiple" },
    { name: "System" },
    { name: "Combination" },
  ];
  const [gamelist, setgamelist] = useState([
    {
      Id: "1", // Unique identifier for the sport
      Name: "Football", // Name of the sport
    },
    {
      Id: "2",
      Name: "Basketball",
    },
    {
      Id: "3",
      Name: "Tennis",
    },
  ]);
  const [EventProgramList, setEventProgramList] = useState([
    // {
    //   Id: "1", // Unique identifier for the event
    //   Name: "Champions League Final", // Name of the event
    //   Sport: {
    //     Id: "2", // Unique identifier for the sport
    //     Name: "Football", // Name of the sport
    //   },
    //   Region: {
    //     Name: "Europe", // Name of the region where the event is held
    //   },
    //   League: {
    //     Name: "UEFA Champions League", // Name of the league associated with the event
    //   },
    //   Date: "2024-06-01T20:00:00Z", // Date and time of the event in ISO format
    // },
    // {
    //   Id: "2",
    //   Name: "NBA Finals Game 1",
    //   Sport: {
    //     Id: "3",
    //     Name: "Basketball",
    //   },
    //   Region: {
    //     Name: "USA",
    //   },
    //   League: {
    //     Name: "NBA",
    //   },
    //   Date: "2024-06-05T21:00:00Z",
    // },
  ]);
  const [SelectedEventProgram, setSelectedEventProgram] = useState("");
  const [MarketList, setMarketList] = useState([
    {
      Ref: "moneyline",
      Name: "Moneyline",
      AdditionalField: "Moneyline",
      ShortName: "1X2",
    },
    {
      Ref: "spread",
      Name: "Spread",
      AdditionalField: "Spread",
      ShortName: "SP",
    },
    {
      Ref: "total",
      Name: "Total",
      AdditionalField: "Total",
      ShortName: "U/O",
    },
    {
      Ref: "dnb",
      Name: "Draw No Bet",
      AdditionalField: "Draw No Bet",
      ShortName: "DNB",
    },
    {
      Ref: "dc",
      Name: "Double Chance",
      AdditionalField: "Double Chance",
      ShortName: "DC",
    },
    {
      Ref: "ggng",
      Name: "Goal/No Goal",
      AdditionalField: "Goal/No Goal",
      ShortName: "GG/NG",
    },
  ]);
  const allSignoList = [
    {
      Ref: "home",
      Name: "Home",
      ShortName: "1",
      Category: "moneyline",
    },
    {
      Ref: "draw",
      Name: "Draw",
      ShortName: "X",
      Category: "moneyline",
    },
    {
      Ref: "away",
      Name: "Away",
      ShortName: "2",
      Category: "moneyline",
    },

    {
      Ref: "home",
      Name: "Home",
      ShortName: "1",
      Category: "spread",
    },
    {
      Ref: "away",
      Name: "Away",
      ShortName: "2",
      Category: "spread",
    },
    {
      Ref: "under",
      Name: "Under",
      ShortName: "U",
      Category: "total",
    },
    {
      Ref: "over",
      Name: "Over",
      ShortName: "O",
      Category: "total",
    },
    {
      Ref: "home",
      Name: "Home",
      ShortName: "1",
      Category: "dnb",
    },
    {
      Ref: "away",
      Name: "Away",
      ShortName: "2",
      Category: "dnb",
    },
    {
      Ref: "home-draw",
      Name: "Home or Draw",
      ShortName: "1X",
      Category: "dc",
    },
    {
      Ref: "home-away",
      Name: "Home or Away",
      ShortName: "12",
      Category: "dc",
    },
    {
      Ref: "draw-away",
      Name: "Draw or Away",
      ShortName: "X2",
      Category: "dc",
    },
    {
      Ref: "gg",
      Name: "Goal",
      ShortName: "GG",
      Category: "ggng",
    },
    {
      Ref: "ng",
      Name: "No Goal",
      ShortName: "NG",
      Category: "ggng",
    },
  ];
  const [SignoList, setSignoList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [ShowsearchInput, setShowSearchInput] = useState(false);
  const [groupData, setgroupData] = useState([]);
  const [CreateSlipType, setCreateSlipType] = useState("");
  const [eventoptionData, seteventoptionData] = useState(eventtypeoption);
  const [selectedEventOption, setselectedEventOption] = useState("");
  const [selectedgame, setselectedgame] = useState("");
  const [createEvent, setcreateEvent] = useState([]);
  const [currencylist, setcurrencylist] = useState();
  const [TotalOdds, setTotalOdds] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formsubmit, setformsubmit] = useState(false);
  const [payedByList, setpayedByList] = useState("");
  const [selectedPayedBy, setselectedPayedBy] = useState("");
  const [SelectedGroupID, setSelectedGroupID] = useState("");
  const [connectedGroup_WalletList, setconnectedGroup_WalletList] =
    useState("");
  const [TotalBetAmountCurrency, setTotalBetAmountCurrency] = useState({
    amount: "",
    currency: "",
  });
  const [TotalBet, setTotalBet] = useState({});
  const [AmountCurrencySubmit, setAmountCurrencySubmit] = useState(false);
  const [SlipStatus, setSlipStatus] = useState("Pending");
  const [PotentialWinAmount, setPotentialWinAmount] = useState(0);
  const [TotalOddEdit, setTotalOddEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      ManualEventName: "",
      ManualLeagueName: "",
      ManualEventDate: "",
      ManualEventMarketCategory: "",
      ManualEventSign: "",
      ManualEventPoints: "",
      ManualEventOdd: "",
      ManualEventCountry: "",

      FixedEventDescription: "",
      FixedEventDate: "",

      SearchEventMarketCategory: "",
      SearchEventSign: "",
      SearchEventPoints: "",
      SearchEventOdd: "",
    },
    validate: (values) => {
      // console.log(values);
      const errors = {};
      if (CreateSlipType === "Manual") {
        if (!values.ManualEventName) {
          errors.ManualEventName = "ManualEventName is required";
        }
        if (!values.ManualLeagueName) {
          errors.ManualLeagueName = "ManualLeagueName is required";
        }
        if (!values.ManualEventDate) {
          errors.ManualEventDate = "ManualEventDate is required";
        }
        if (!values.ManualEventMarketCategory) {
          errors.ManualEventMarketCategory =
            "ManualEventMarketCategory is required";
        }
        if (!values.ManualEventSign) {
          errors.ManualEventSign = "ManualEventSign is required";
        }
        if (!values.ManualEventOdd) {
          errors.ManualEventOdd = "ManualEventOdd is required";
        } else {
          const [integerPart, decimalPart] = values.ManualEventOdd.split(".");
          if (decimalPart && decimalPart.length === 1) {
            errors.ManualEventOdd =
              "Minimum 2 digits is required after decimal";
          }
        }

        if (!values.ManualEventCountry) {
          errors.ManualEventCountry = "ManualEventCountry is required";
        }
        return errors;
      }

      if (CreateSlipType === "Fixed") {
        if (!values.FixedEventDescription) {
          errors.FixedEventDescription = "FixedEventDescription is required";
        }
        if (!values.FixedEventDate) {
          errors.FixedEventDate = "FixedEventDate is required";
        }
        return errors;
      }

      if (CreateSlipType === "Search") {
        if (!values.SearchEventMarketCategory) {
          errors.SearchEventMarketCategory =
            "SearchEventMarketCategory is required";
        }
        if (!values.SearchEventSign) {
          errors.SearchEventSign = "SearchEventSign is required";
        }
        if (!values.SearchEventOdd) {
          errors.SearchEventOdd = "SearchEventOdd is required";
        } else {
          const [integerPart, decimalPart] = values.SearchEventOdd.split(".");
          if (decimalPart && decimalPart.length === 1) {
            errors.SearchEventOdd =
              "Minimum 2 digits is required after decimal";
          }
        }

        return errors;
      }
    },

    onSubmit: (values) => {
      // console.log(values);
      if (CreateSlipType === "Manual") {
        const dataObj = {
          name: values.ManualEventName,
          leagueName: values.ManualLeagueName,
          country: values.ManualEventCountry,
          date: values.ManualEventDate,
          marketCategory: values.ManualEventMarketCategory,
          sign: values.ManualEventSign,
          points: values.ManualEventPoints,
          odd: values.ManualEventOdd,
          EventDetails: {
            Name: values.ManualEventName,
            Region: { Name: values.ManualEventCountry },
            League: { Name: values.ManualLeagueName },
            Date: values.ManualEventDate,
            Sport: selectedgame,
          },
        };
        setcreateEvent([...createEvent, dataObj]);
        formik.resetForm();
        setSignoList([]);
        setformsubmit(false);
      }

      if (CreateSlipType === "Fixed") {
        const dataObj = {
          description: values.FixedEventDescription,
          date: values.FixedEventDate,
          sportName: selectedgame?.Name,
        };
        setcreateEvent([...createEvent, dataObj]);
        formik.resetForm();
        setformsubmit(false);
      }

      if (CreateSlipType === "Search") {
        const dataObj = {
          marketCategory: values.SearchEventMarketCategory,
          sign: values.SearchEventSign,
          points: values.SearchEventPoints,
          odd: values.SearchEventOdd,
          EventDetails: SelectedEventProgram,
        };
        console.log(dataObj);
        setcreateEvent([...createEvent, dataObj]);
        formik.resetForm();
        setSignoList([]);
        setformsubmit(false);
        setSelectedEventProgram("");
      }
    },
  });
  // console.log(TotalOdds)
  // console.log(createEvent);

  useEffect(() => {
    CalculateFuncTotalOdds();
  }, [createEvent]);

  // function CalculateFuncTotalOdds() {
  //   const sumOfOdd = createEvent.reduce(
  //     (sum, item) => sum + parseFloat(item.odd),
  //     0
  //   );
  //   setTotalOdds(sumOfOdd);
  // }

  function CalculateFuncTotalOdds() {
    let sumOfOdd = 1;

    if (createEvent.length > 0) {
      createEvent.forEach((item) => {
        const oddValue = parseFloat(item.odd || 0);
        sumOfOdd *= oddValue;
        // console.log(sumOfOdd)
        const sumOfOddString = sumOfOdd.toString(); // Convert to string
        const [integerPart, decimalPart] = sumOfOddString.split(".");
        if (decimalPart && decimalPart.length > 3) {
          // console.log('greater')
          setTotalOdds(sumOfOdd.toFixed(3));
        } else {
          // console.log('less')
          setTotalOdds(sumOfOdd);
        }

        // const hasNonZeroFraction = sumOfOdd % 1 !== 0;
        // const roundedSum = hasNonZeroFraction ? sumOfOdd.toFixed(3) : sumOfOdd;
        // setTotalOdds(roundedSum);
      });
    } else {
      setTotalOdds(0);
    }
  }

  function getsportlisting() {
    setLoading(true);
    GetService(API_URL.GET_SPORTS).then((res) => {
      setLoading(false);
      console.log(res);
      if (res?.data?.data?.Code !== "expiry") {
        setgamelist(res?.data?.data);
        console.log(res?.data?.data);
      }
    });
  }

  function getMarketListing() {
    setLoading(true);

    const refMappings = {
      moneyline: "1X2",
      spread: "Handicap",
      total: "Under/Over",
      dnb: "Draw No Bet",
      dc: "Double Chance",
      ggng: "Goal/No Goal",
    };

    GetService(`${API_URL.GET_MARKETS}?SportId=${selectedgame?.Id}`).then(
      (res) => {
        setLoading(false);
        if (res?.status) {
          // setMarketList(res?.data?.data);
          const updatedMarketList = res?.data?.data.map((market) => ({
            ...market,
            AdditionalField: refMappings[market.Ref],
          }));
          // console.log(updatedMarketList)
          setMarketList(updatedMarketList);
          formik.setFieldValue("SearchEventSign", "");
        }
      }
    );
  }

  useEffect(() => {
    return; // change this later
    if (selectedgame?.Id) {
      getMarketListing();
      setSignoList([]);
      formik.setFieldValue("SearchEventSign", "");
      formik.setFieldValue("ManualEventSign", "");
    }
  }, [selectedgame]);

  function getgrouplisting() {
    GetService(API_URL.GROUP_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        const Data = res?.data?.data?.filter(
          (data) => data?.type === "generic" && data?.tag === "Passivity"
        );
        setgroupData(Data);
      }
    });
  }
  function getCurrencylisting() {
    getCurrencyListing()
      .then((res) => {
        setcurrencylist(res);
      })
      .catch((err) => {
        console.error("Error fetching currency list:", err);
        toast.error("Error fetching currency list");
      });
  }

  const handleInputChange = (event) => {
    // console.log(event.target.value);
    setEventProgramList([]);
    if (event.target.value.length >= 3) {
      GetSearchEventList(event.target.value);
    }
    setSearchInput(event.target.value);
  };

  function GetSearchEventList(key) {
    setLoading(true);
    PostService(API_URL.GET_SEARCH_EVENT_LIST, { Query: key }).then((res) => {
      setLoading(false);
      console.log(res);
      if (res?.data?.status) {
        setEventProgramList(res?.data?.data);
      } else {
        showErrorAlert(res?.data?.message);
      }
    });
  }

  useEffect(() => {
    // getsportlisting();
    getgrouplisting();
    getCurrencylisting();
  }, []);

  function HandleHardReset() {
    formik.resetForm();
    setformsubmit(false);
    setSignoList([]);
    setcreateEvent([]);
    setTotalBet({});
    setCreateSlipType("");
    setselectedPayedBy("");
    setselectedgame("");
    setconnectedGroup_WalletList("");
    setSelectedGroupID("");
    setpayedByList("");
    // setgroupData('')
    setSlipStatus("Pending");
  }

  function HandleReset() {
    formik.resetForm();
    setformsubmit(false);
    setSignoList([]);
    setcreateEvent([]);
    setTotalBet({});
  }

  useEffect(() => {
    HandleReset();
    setSlipStatus("Pending");
  }, [CreateSlipType]);

  function removeEvent(i) {
    const arr = [...createEvent];
    arr.splice(i, 1);
    setcreateEvent(arr);
  }

  function GetGroupDetail(e) {
    setLoading(true);
    PostService(API_URL.GROUP_DETAIL, { id: e }).then((res) => {
      setLoading(false);
      if (res?.status) {
        const data = {
          id: res?.data?.data?.ref_id,
          name: `${res?.data?.data?.referent?.name}`,
          role: "refrent",
        };
        const data1 = {
          id: res?.data?.data?.player_id,
          name: res?.data?.data?.player?.name,
          role: "player",
        };
        setpayedByList([data, data1]);
      }
    });
  }

  function GetGroupOrWallet(e) {
    const data = e;
    setLoading(true);
    GetService(
      `${API_URL.GET_CONNECTED_GROUP_OR_WALLET}?id=${
        data?.role === "refrent" ? SelectedGroupID : data?.id
      }&type=${data?.role}`
    ).then((res) => {
      setLoading(false);
      if (res?.status) {
        if (data?.role === "player") {
          setconnectedGroup_WalletList(res?.data?.data);
          console.log(res?.data?.data);
        } else {
          // console.log(res?.data?.data[0]?.group_detail);
          // setconnectedGroup_WalletList(res?.data?.data[0]?.group_detail);
          setconnectedGroup_WalletList(ConnectedGroups(res?.data?.data[0]));
        }

        const dropdownElement = document.getElementById("dropdown-basic");
        const groupSelectionDiv = document.getElementById("group-selection");
        console.log("GRSD : ", groupSelectionDiv.classList);
        if (groupSelectionDiv && groupSelectionDiv.classList.contains("show")) {
          console.log("show found");
          // groupSelectionDiv.classList.remove("show");
          // if (dropdownElement) {
          //   dropdownElement.click();
          // }
        }
        if (dropdownElement) {
          dropdownElement.click();
        }
      }
    });
  }

  const ConnectedGroups = (item) => {
    console.log(item);
    const groupsArray = [];
    item?.group_detail &&
      item?.group_detail.map((data) => groupsArray.push(data?.satellite_group));
    item?.groups_detail &&
      item?.groups_detail.map((data) =>
        groupsArray.push(data?.group_main_detail)
      );

    return groupsArray;
  };

  const [checkedStatus, setCheckedStatus] = React.useState(() => {
    const initialState = {};
    if (Array.isArray(connectedGroup_WalletList)) {
      connectedGroup_WalletList.forEach((data) => {
        console.log(data);
        initialState[data.id] = false;
      });
    }
    // console.log(initialState)
    return initialState;
  });

  const [Selected_Wallet_GroupID, setSelected_Wallet_GroupID] = useState("");

  // console.log(checkedStatus)

  const handleChange = (data, event) => {
    setSelected_Wallet_GroupID(data && event.target.checked ? data : "");
    console.log(data);
    // console.log(id, event.target.checked)
    setCheckedStatus((prevCheckedStatus) => {
      console.log(prevCheckedStatus);
      const newCheckedStatus = { [data.id]: !prevCheckedStatus[data.id] };
      // -------- Code for multi-select option-------------------
      // const newCheckedStatus = { ...prevCheckedStatus };
      // newCheckedStatus[id] = !newCheckedStatus[id];
      // if (!newCheckedStatus[id]) {
      //   delete newCheckedStatus[id];
      // }
      console.log(newCheckedStatus);
      return newCheckedStatus;
    });
  };
  // console.log(checkedStatus);

  function AddAmountCurrencyFunc() {
    if (TotalBetAmountCurrency.amount && TotalBetAmountCurrency.currency) {
      let amount = parseFloat(TotalBetAmountCurrency.amount);
      switch (TotalBetAmountCurrency.currency) {
        case "BTC":
          amount = amount.toFixed(6);
          break;
        case "ETH":
          amount = amount.toFixed(5);
          break;
        default:
          amount = amount.toFixed(2);
          break;
      }

      TotalBetAmountCurrency.amount = amount;
      setTotalBet(TotalBetAmountCurrency);
      setTotalBetAmountCurrency({ amount: "", currency: "" });
      setAmountCurrencySubmit(false);
    }
  }

  const handleInputDecimal = (e) => {
    const { name, value } = e.target;
    // Format the entered value to allow at most 3 digits after the decimal point
    const formattedValue = value.replace(/^(-?\d+\.\d{0,3})\d*$/, "$1");

    formik.handleChange({
      target: {
        name,
        value: formattedValue,
      },
    });
  };

  const handleInputPositiveDecimal = (e) => {
    const { name, value } = e.target;
    // Remove any non-digit or non-dot characters
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    // console.log(cleanedValue)
    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = cleanedValue.split(".");

    // if (!decimalPart || (decimalPart && decimalPart.length >= 2)) {
    // Ensure only positive numbers and limit decimal places to 3
    let formattedValue =
      integerPart + (decimalPart ? `.${decimalPart.slice(0, 3)}` : "");
    // console.log(formattedValue)

    formik.handleChange({
      target: {
        name,
        value: formattedValue,
      },
    });
    // }
    // else if (decimalPart && decimalPart.length === 1) {
    //   // If there's only one digit after the decimal point, keep it
    //   formik.handleChange({
    //     target: {
    //       name,
    //       value: cleanedValue,
    //     },
    //   });
    // }
  };

  const handleUpdateTotalOdds = (e) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/[^0-9.]/g, "");

    const [integerPart, decimalPart] = cleanedValue.split(".");

    let formattedValue =
      integerPart + (decimalPart ? `.${decimalPart.slice(0, 3)}` : "");

    setTotalOdds(formattedValue);
  };

  function ToFixedValue(value) {
    const fixedValue = parseFloat(value).toFixed(3);
    console.log(parseFloat(fixedValue));
    return parseFloat(fixedValue);
  }

  function AmountBasedCurrency(e, cur) {
    let amount = parseFloat(e);
    switch (cur || TotalBetAmountCurrency.currency) {
      case "BTC":
        amount = amount.toFixed(6);
        break;
      case "ETH":
        amount = amount.toFixed(5);
        break;
      default:
        amount = amount.toFixed(2);
        break;
    }
    console.log(parseFloat(amount));
    console.log(amount);
    return parseFloat(amount);
  }

  useEffect(() => {
    potentialwinAmount();
  }, [TotalOdds, TotalBet]);

  function potentialwinAmount() {
    let amount = TotalOdds * (TotalBet?.amount || 0);
    switch (TotalBet.currency) {
      case "BTC":
        amount = amount.toFixed(6);
        break;
      case "ETH":
        amount = amount.toFixed(5);
        break;
      default:
        amount = amount.toFixed(2);
        break;
    }
    // console.log(parseFloat(amount));
    // console.log(amount);
    setPotentialWinAmount(amount);
    return amount;
  }

  function GetSignoList(market) {
    formik.setFieldValue("SearchEventSign", "");

    const filteredSignoList = allSignoList.filter(
      (signo) => signo.Category === market
    );
    setSignoList(filteredSignoList);
    return;
    setLoading(true);
    GetService(
      `${API_URL.GET_SIGN_OR_SELECTION_LIST}?SportId=${selectedgame?.Id}&MarketRef=${market}`
    ).then((res) => {
      setLoading(false);
      if (res?.status) {
        setSignoList(res?.data?.data);
        // console.log(formik.values.SearchEventMarketCategory)
      }
    });
  }

  function CreateNewSlip() {
    // console.log(CreateSlipType);return
    if (createEvent.length < 1) {
      showWarningAlert("Please Create a Event First!");
      return;
    } else if (!TotalBet.amount) {
      showWarningAlert("Please Add Total Bet Amount & Currency!");
      return;
    } else if (!SelectedGroupID) {
      showWarningAlert("Please select the betting group to create a slip!");
      return;
    }
    setLoading(true);
    // console.log(createEvent);

    const SearchOrManualEventArray = createEvent.map((item) => ({
      SportId: selectedgame?.Id,
      // EventId: item.EventDetails?.Id,
      EventDetails: item.EventDetails,
      PeriodRef: "ft",
      MarketRef: item.marketCategory,
      Points: item.points * 100,
      SelectionRef: item.sign,
      Price: Number(item.odd * 100),
    }));

    const FixedEventArray = createEvent.map((item) => ({
      // SportId: selectedgame?.Id,
      Date: item?.date,
      Description: item?.description,
    }));

    const DataObj = {
      Stake: {
        Currency: TotalBet.currency,
        Value: TotalBet.amount,
      },
      Lines:
        CreateSlipType !== "Fixed" ? SearchOrManualEventArray : FixedEventArray,
      total_odds: TotalOdds,
      // potential_win: (TotalBet.amount * TotalOdds).toFixed(3),
      potential_win: PotentialWinAmount,
      status: SlipStatus,
      group_id: SelectedGroupID,
      connected: {
        role: Selected_Wallet_GroupID?.id ? selectedPayedBy?.role : "",
        attach_id: Selected_Wallet_GroupID?.id,
        tag: Selected_Wallet_GroupID?.tag,
      },
      slip_type: CreateSlipType,
    };

    PostService(API_URL.CREATE_SLIP, DataObj)
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res?.data?.status) {
          navigate("/panel/slips");
        } else {
          showErrorAlert("Server Error");
        }
      })
      .catch((error) => {
        showErrorAlert("Server Error");
        console.error(error);
        setLoading(false);
      });
  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30">New Slip</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link to={"/panel/slips"} className="breadcrumb-item">
                    Slips
                  </Link>
                  <span className="breadcrumb-item active">New Slip</span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row px-3">
                    <div className="col-sm-5 p-0">
                      <div className="row my-4">
                        <div className="col-12 d-flex justify-content-center">
                          <div className="col-12">
                            <i
                              class="fa-solid fa-magnifying-glass position-absolute"
                              style={{ zIndex: "2", top: "15px", left: "35px" }}
                            ></i>
                            <input
                              type="text"
                              placeholder="Search Event ..."
                              className="form-control pl-5"
                              onClick={() => setShowSearchInput(true)}
                              value={searchInput}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div
                            className="card groupPersonalCard rounded  position-absolute"
                            style={{
                              minHeight: "60px",
                              zIndex: "1",
                              width: "93%",
                              top: "103%",
                              display: ShowsearchInput ? "block" : "none",
                            }}
                          >
                            <div className="align-items-baseline d-flex flex-column justify-content-center pt-2">
                              <ul
                                className="px-3 w-100 list-inline"
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {EventProgramList?.map((data, i) => (
                                  <li
                                    key={i}
                                    className="my-2 eventProgram px-2 py-1 rounded"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setCreateSlipType("Search");
                                      setSelectedEventProgram(data);
                                      setSearchInput("");
                                      setShowSearchInput(false);
                                      setEventProgramList([]);
                                    }}
                                  >
                                    {data?.Sport?.Name === "Football" ? (
                                      <i class="fa-regular fa-futbol"></i>
                                    ) : data?.Sport?.Name === "Basketball" ? (
                                      <i class="fa-solid fa-basketball"></i>
                                    ) : data?.Sport?.Name === "Tennis" ? (
                                      <i class="fa fa-circle ">
                                        {/* <img src={TennisIcon} alt="Tennis" /> */}
                                      </i>
                                    ) : (
                                      ""
                                    )}
                                    &nbsp; {data?.Name} -{" "}
                                    {format(
                                      parseISO(data?.Date),
                                      "dd/MM/yyyy HH:mm"
                                    )}
                                    {/* ⊗ &nbsp; Juva va Manch - 20/01/2024 20:30 */}
                                  </li>
                                ))}

                                {EventProgramList.length < 1 && (
                                  <li className="my-2 text-center">
                                    No Event Found
                                  </li>
                                )}

                                {/* <li className="font-weight-bold px-2 py-1 text-center text-gray text-small">
                                  No Event Found
                                </li> */}
                              </ul>
                              <div className="row mt-3 w-100">
                                <div className="col-12 p-0">
                                  <div className="card groupPersonalCard rounded">
                                    <div className="card-body pb-5">
                                      <label className="small">Actions</label>
                                      <div>
                                        <button
                                          className="btn p-0 mt-2 fontColor"
                                          onClick={() => {
                                            setCreateSlipType("Manual");
                                            setSearchInput("");
                                            setShowSearchInput(false);
                                            setEventProgramList([]);
                                          }}
                                        >
                                          <i
                                            class="fa fa-ticket"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Add Manual Slip
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          className="btn p-0 mt-3 fontColor"
                                          onClick={() => {
                                            setCreateSlipType("Fixed");
                                            setSearchInput("");
                                            setShowSearchInput(false);
                                            setEventProgramList([]);
                                          }}
                                        >
                                          <span className="font-weight-bold">
                                            #
                                          </span>{" "}
                                          Add Fixed Amount
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row my-4">
                        <div className="col-12 px-5 d-flex">
                          {gamelist &&
                            gamelist?.map((item, i) => (
                              <div
                                key={i}
                                className={
                                  selectedgame?.Id === item?.Id
                                    ? "align-items-center badge badge-dark px-3 mr-2 cursor"
                                    : "align-items-center badge badge-primary px-3 mr-2 cursor"
                                }
                                onClick={() => setselectedgame(item)}
                              >
                                {item?.Name === "Football" && (
                                  <i
                                    class="fa-regular fa-futbol"
                                    style={{ fontSize: "large" }}
                                  ></i>
                                )}
                                {item?.Name === "Basketball" && (
                                  <i
                                    class="fa-solid fa-basketball"
                                    style={{ fontSize: "large" }}
                                  ></i>
                                )}
                                {item?.Name === "Tennis" && (
                                  <i
                                    class="fa fa-circle "
                                    style={{ fontSize: "large" }}
                                  >
                                    {/* <img src={TennisIcon} /> */}
                                  </i>
                                )}
                                <span className="mx-2 font-weight-bold">
                                  {item?.Name}
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col-12">
                          <div className="d-flex justify-content-around w-100 ">
                            {eventoptionData &&
                              eventoptionData?.map((item, i) => (
                                <div
                                  key={i}
                                  className={
                                    selectedEventOption === i
                                      ? "cursor font-weight-bold px-2 py-1 rounded text-small border"
                                      : "cursor font-weight-bold px-2 py-1 rounded text-small "
                                  }
                                  onClick={() => setselectedEventOption(i)}
                                >
                                  {item?.name}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      {/* add manual slip form */}
                      {CreateSlipType === "Fixed" && (
                        <div className="card groupPersonalCard rounded mt-5 bg-light">
                          <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-12">
                                  <h4> New Fixed Amount</h4>
                                  <div className="mt-3">
                                    <textarea
                                      name="FixedEventDescription"
                                      value={
                                        formik.values.FixedEventDescription
                                      }
                                      onChange={formik.handleChange}
                                      placeholder="Description"
                                      className={`form-control ${
                                        formik.errors.FixedEventDescription &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                    ></textarea>
                                  </div>
                                  <div className="my-3">
                                    <input
                                      type="date"
                                      className={`form-control ${
                                        formik.errors.FixedEventDate &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                      name="FixedEventDate"
                                      value={formik.values.FixedEventDate}
                                      onChange={formik.handleChange}
                                    ></input>
                                  </div>
                                  <div className=" mt-2">
                                    <div className="d-flex justify-content-end">
                                      <span
                                        className="add_circle bg-danger"
                                        type="button"
                                        onClick={() => {
                                          setCreateSlipType("");
                                          HandleReset();
                                        }}
                                      >
                                        <i class="fa-solid fa-xmark"></i>
                                      </span>
                                      <span className="add_circle mx-2 bg-success">
                                        <button
                                          type="submit"
                                          className="btn text-white"
                                          onClick={() => setformsubmit(true)}
                                        >
                                          <i class="fa-solid fa-check"></i>
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}

                      {CreateSlipType === "Manual" && (
                        <div className="card groupPersonalCard rounded mt-5 bg-light">
                          <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-12">
                                  <h4>New Manual Event</h4>

                                  <div className="mt-3">
                                    <input
                                      type="text"
                                      placeholder="Event Name"
                                      className={`form-control ${
                                        formik.errors.ManualEventName &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                      name="ManualEventName"
                                      value={formik.values.ManualEventName}
                                      onChange={formik.handleChange}
                                    />
                                  </div>

                                  <div className="row d-flex mt-3">
                                    <div className="col-6 pr-0">
                                      <input
                                        type="text"
                                        placeholder="Country"
                                        className={`form-control ${
                                          formik.errors.ManualEventCountry &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        name="ManualEventCountry"
                                        value={formik.values.ManualEventCountry}
                                        onChange={formik.handleChange}
                                      />
                                    </div>

                                    <div className="col-6 pl-1">
                                      <input
                                        type="text"
                                        placeholder="League Name"
                                        className={`form-control ${
                                          formik.errors.ManualLeagueName &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        name="ManualLeagueName"
                                        value={formik.values.ManualLeagueName}
                                        onChange={formik.handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="my-3">
                                    <input
                                      type="datetime-local"
                                      name="ManualEventDate"
                                      className={`form-control ${
                                        formik.errors.ManualEventDate &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                      value={formik.values.ManualEventDate}
                                      onChange={formik.handleChange}
                                    />
                                  </div>

                                  <div className="row d-flex my-3">
                                    <div className="col-6 ">
                                      <select
                                        name="ManualEventMarketCategory"
                                        className={`form-control ${
                                          formik.errors
                                            .ManualEventMarketCategory &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        value={
                                          formik.values
                                            .ManualEventMarketCategory
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          GetSignoList(e.target.value);
                                        }}
                                      >
                                        <option value="" selected hidden>
                                          Market Category
                                        </option>
                                        {selectedgame &&
                                          MarketList.length < 1 && (
                                            <option disabled>
                                              No data found
                                            </option>
                                          )}
                                        {!selectedgame &&
                                          MarketList.length < 1 && (
                                            <option disabled>
                                              Select Sport First
                                            </option>
                                          )}
                                        {MarketList.map((data) => (
                                          <option value={data?.Ref}>
                                            {data?.AdditionalField}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div
                                      className={
                                        formik.values
                                          .ManualEventMarketCategory ===
                                          "total" ||
                                        formik.values
                                          .ManualEventMarketCategory ===
                                          "spread"
                                          ? "col-3 pl-0"
                                          : "col-6 pl-0"
                                      }
                                    >
                                      <select
                                        className={`form-control ${
                                          formik.errors.ManualEventSign &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        name="ManualEventSign"
                                        onChange={formik.handleChange}
                                        value={formik.values.ManualEventSign}
                                      >
                                        <option value="" selected hidden>
                                          Signo
                                        </option>
                                        {formik.values
                                          .ManualEventMarketCategory &&
                                          SignoList?.length < 1 && (
                                            <option disabled>
                                              No data found
                                            </option>
                                          )}
                                        {!formik.values
                                          .ManualEventMarketCategory &&
                                          SignoList?.length < 1 && (
                                            <option disabled>
                                              Select Category First
                                            </option>
                                          )}
                                        {SignoList &&
                                          (formik.values
                                            .ManualEventMarketCategory ===
                                            "total" ||
                                            formik.values
                                              .ManualEventMarketCategory ===
                                              "ggng") &&
                                          SignoList?.map((data) => (
                                            <option value={data?.Ref}>
                                              {data?.Name}
                                            </option>
                                          ))}
                                        {SignoList &&
                                          formik.values
                                            .ManualEventMarketCategory !==
                                            "total" &&
                                          formik.values
                                            .ManualEventMarketCategory !==
                                            "ggng" &&
                                          SignoList?.map((data) => (
                                            <option value={data?.Ref}>
                                              {data?.ShortName}
                                            </option>
                                          ))}
                                      </select>
                                    </div>

                                    {(formik.values
                                      .ManualEventMarketCategory === "total" ||
                                      formik.values
                                        .ManualEventMarketCategory ===
                                        "spread") && (
                                      <div className="col-3 pl-0">
                                        <input
                                          type="number"
                                          placeholder="Points"
                                          className={`form-control`}
                                          name="ManualEventPoints"
                                          value={
                                            formik.values.ManualEventPoints
                                          }
                                          onChange={handleInputDecimal}
                                          onWheel={(e) => e.target.blur()}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div className="row d-flex my-3">
                                    <div className="col-12">
                                      <input
                                        type="number"
                                        placeholder="Odd"
                                        name="ManualEventOdd"
                                        className={`form-control ${
                                          formik.errors.ManualEventOdd &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        value={formik.values.ManualEventOdd}
                                        // onChange={formik.handleChange}
                                        onChange={handleInputPositiveDecimal}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                    {formik.values.ManualEventOdd &&
                                      formik.errors.ManualEventOdd && (
                                        <small className="px-3 pt-1 text-danger">
                                          {formik.errors.ManualEventOdd}
                                        </small>
                                      )}
                                  </div>

                                  <div className=" mt-2">
                                    <div className="d-flex justify-content-end">
                                      <span
                                        className="add_circle bg-danger"
                                        type="button"
                                        onClick={() => {
                                          setCreateSlipType("");
                                          HandleReset();
                                        }}
                                      >
                                        <i class="fa-solid fa-xmark"></i>
                                      </span>
                                      <span className="add_circle mx-2 bg-success">
                                        <button
                                          type="submit"
                                          className="btn text-white"
                                          onClick={() => setformsubmit(true)}
                                        >
                                          <i class="fa-solid fa-check"></i>
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}

                      {CreateSlipType === "Search" && SelectedEventProgram && (
                        <div className="card groupPersonalCard rounded mt-5 bg-light">
                          <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-12">
                                  <h4> New Event</h4>

                                  {SelectedEventProgram && (
                                    <div className="mt-3 d-flex">
                                      {SelectedEventProgram?.Sport?.Name ===
                                      "Football" ? (
                                        <i
                                          class="fa-regular fa-futbol pt-3"
                                          style={{ fontSize: "larger" }}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa-solid fa-basketball pt-3"
                                          style={{ fontSize: "larger" }}
                                        ></i>
                                      )}

                                      <div className="pl-2">
                                        <small>
                                          {SelectedEventProgram?.Region?.Name} -{" "}
                                          {SelectedEventProgram?.League?.Name}
                                        </small>
                                        <p>
                                          {SelectedEventProgram?.Name} -{" "}
                                          {format(
                                            parseISO(
                                              SelectedEventProgram?.Date
                                            ),
                                            "dd/MM/yyyy HH:mm"
                                          )}
                                        </p>
                                        {/* <p>Juva va Manch - 20/01/2024 20:30</p> */}
                                      </div>
                                    </div>
                                  )}

                                  <div className="row d-flex my-3">
                                    <div className="col-6 ">
                                      <select
                                        className={`form-control ${
                                          formik.errors
                                            .SearchEventMarketCategory &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        name="SearchEventMarketCategory"
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          GetSignoList(e.target.value);
                                        }}
                                        value={
                                          formik.values
                                            .SearchEventMarketCategory
                                        }
                                      >
                                        <option value="" selected hidden>
                                          Market Category
                                        </option>
                                        {selectedgame &&
                                          MarketList.length < 1 && (
                                            <option disabled>
                                              No data found
                                            </option>
                                          )}
                                        {!selectedgame &&
                                          MarketList.length < 1 && (
                                            <option disabled>
                                              Select Sport First
                                            </option>
                                          )}
                                        {MarketList.map((data) => (
                                          <option value={data?.Ref}>
                                            {data?.AdditionalField}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div
                                      className={
                                        formik.values
                                          .SearchEventMarketCategory ===
                                          "total" ||
                                        formik.values
                                          .SearchEventMarketCategory ===
                                          "spread"
                                          ? "col-3 pl-0"
                                          : "col-6 pl-0"
                                      }
                                    >
                                      <select
                                        className={`form-control ${
                                          formik.errors.SearchEventSign &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        name="SearchEventSign"
                                        onChange={formik.handleChange}
                                        value={formik.values.SearchEventSign}
                                      >
                                        <option value="" selected hidden>
                                          Signo
                                        </option>
                                        {formik.values
                                          .SearchEventMarketCategory &&
                                          SignoList?.length < 1 && (
                                            <option disabled>
                                              No data found
                                            </option>
                                          )}
                                        {!formik.values
                                          .SearchEventMarketCategory &&
                                          SignoList?.length < 1 && (
                                            <option disabled>
                                              Select Category First
                                            </option>
                                          )}
                                        {SignoList &&
                                          (formik.values
                                            .SearchEventMarketCategory ===
                                            "total" ||
                                            formik.values
                                              .SearchEventMarketCategory ===
                                              "ggng") &&
                                          SignoList?.map((data) => (
                                            <option value={data?.Ref}>
                                              {data?.Name}
                                            </option>
                                          ))}
                                        {SignoList &&
                                          formik.values
                                            .SearchEventMarketCategory !==
                                            "total" &&
                                          formik.values
                                            .SearchEventMarketCategory !==
                                            "ggng" &&
                                          SignoList?.map((data) => (
                                            <option value={data?.Ref}>
                                              {data?.ShortName}
                                            </option>
                                          ))}
                                      </select>
                                    </div>

                                    {(formik.values
                                      .SearchEventMarketCategory === "total" ||
                                      formik.values
                                        .SearchEventMarketCategory ===
                                        "spread") && (
                                      <div className="col-3 pl-0">
                                        <input
                                          type="number"
                                          placeholder="Points"
                                          className={`form-control`}
                                          name="SearchEventPoints"
                                          value={
                                            formik.values.SearchEventPoints
                                          }
                                          onChange={handleInputDecimal}
                                          onWheel={(e) => e.target.blur()}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div className="row my-3">
                                    <div className="col-12">
                                      <input
                                        type="number"
                                        placeholder="Odd"
                                        className={`form-control ${
                                          formik.errors.SearchEventOdd &&
                                          formsubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        name="SearchEventOdd"
                                        value={formik.values.SearchEventOdd}
                                        // onChange={formik.handleChange}
                                        onChange={handleInputPositiveDecimal}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                    {formik.values.SearchEventOdd &&
                                      formik.errors.SearchEventOdd && (
                                        <small className="px-3 pt-1 text-danger">
                                          {formik.errors.SearchEventOdd}
                                        </small>
                                      )}
                                  </div>

                                  <div className=" mt-2">
                                    <div className="d-flex justify-content-end">
                                      <span
                                        className="add_circle bg-danger"
                                        type="button"
                                        onClick={() => {
                                          // setCreateSlipType("");
                                          setSelectedEventProgram("");
                                          HandleReset();
                                        }}
                                      >
                                        <i class="fa-solid fa-xmark"></i>
                                      </span>
                                      <span className="add_circle mx-2 bg-success">
                                        <button
                                          type="submit"
                                          className="btn text-white"
                                          onClick={() => setformsubmit(true)}
                                        >
                                          <i class="fa-solid fa-check"></i>
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-sm-7">
                      <div className="bg-dark w-100 rounded-top">
                        {/* <div className="align-items-center d-flex pl-2 py-2"> */}
                        <div className="d-flex py-1">
                          {/* <div className="col-12"> */}
                          <h4 className="col-4 fontWhite mt-2 pt-1">
                            SLIP
                            {createEvent.length > 0
                              ? createEvent.length > 1
                                ? " - Parlay"
                                : " - Single"
                              : ""}
                          </h4>

                          <div className="col-8 d-flex">
                            <div className="col-6 pl-0 py-1">
                              <select
                                className="form-control form-control-sm  bg-dark"
                                onChange={(e) => {
                                  GetGroupDetail(e.target.value);
                                  setSelectedGroupID(e.target.value);
                                  setpayedByList("");
                                  setselectedPayedBy("");
                                  setCheckedStatus("");
                                  setSelected_Wallet_GroupID("");
                                }}
                                value={SelectedGroupID}
                              >
                                <option value={""} selected hidden>
                                  Select Group
                                </option>
                                {groupData.length < 1 && (
                                  <option value="" disabled>
                                    No Group Found
                                  </option>
                                )}
                                {groupData &&
                                  groupData?.map((item) => (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-6 pl-0 py-1">
                              <select
                                className="form-control form-control-sm bg-dark"
                                disabled={payedByList.length < 1}
                                style={
                                  payedByList.length < 1
                                    ? { opacity: "0.5" }
                                    : {}
                                }
                                onChange={(e) => {
                                  const selectedItem = payedByList.find(
                                    (item) => item?.id === +e.target.value
                                  );

                                  setselectedPayedBy(selectedItem);
                                  GetGroupOrWallet(selectedItem);
                                  setCheckedStatus("");
                                }}
                                value={selectedPayedBy.id}
                              >
                                <option value="" selected hidden>
                                  Payed by
                                </option>
                                {/* <option value="" disabled>Select</option> */}
                                {payedByList &&
                                  payedByList?.map((item) => (
                                    <option key={item.id} value={item?.id}>
                                      {item?.name || "No"}{" "}
                                      {`(${
                                        item?.role === "refrent"
                                          ? "Referent"
                                          : "Player"
                                      })`}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>

                      <div
                        className="card card-bg rounded-0"
                        style={{
                          minHeight: "420px",
                          maxHeight: "420px",
                          overflowY: "auto",
                        }}
                      >
                        <div className="card-body p-0">
                          {selectedPayedBy && (
                            <Dropdown className="border-0" id="group-selection">
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="align-items-center badge badge-secondary d-flex btn-secondary justify-content-between px-3 w-100 border-0 rounded-0"
                              >
                                <label className="pt-1">
                                  Choose{" "}
                                  {selectedPayedBy?.role === "refrent"
                                    ? "Group"
                                    : "Wallet"}{" "}
                                  :{" "}
                                </label>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="w-100 p-3 px-4">
                                {connectedGroup_WalletList &&
                                  selectedPayedBy?.role === "player" &&
                                  connectedGroup_WalletList.map(
                                    (data, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-between"
                                      >
                                        <p>{data?.name}</p>
                                        <span className="toggle-switch">
                                          <Switch
                                            checked={
                                              checkedStatus[data.id] || false
                                            }
                                            size="small"
                                            onChange={(e) =>
                                              handleChange(data, e)
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )
                                  )}
                                {connectedGroup_WalletList &&
                                  selectedPayedBy?.role === "refrent" &&
                                  connectedGroup_WalletList.map(
                                    (data, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-between"
                                      >
                                        <p>{data?.name}</p>
                                        <span className="toggle-switch">
                                          <Switch
                                            checked={
                                              checkedStatus[data.id] || false
                                            }
                                            size="small"
                                            onChange={(e) =>
                                              handleChange(data, e)
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        </span>
                                      </div>
                                    )
                                  )}
                                {connectedGroup_WalletList.length === 0 && (
                                  <div className="text-center">
                                    No Data Found
                                  </div>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}

                          <div className="col-12 pb-1">
                            {createEvent?.length === 0 && (
                              <h3 className="h5 mt-5 text-center text-small">
                                Empty Betslip
                              </h3>
                            )}

                            {(CreateSlipType === "Manual" ||
                              CreateSlipType === "Search") &&
                              createEvent.map((data, i) => (
                                <div key={i} className="d-flex my-3">
                                  <div className="col-1 rounded-left bg-dark align-items-center d-flex justify-content-center">
                                    <i
                                      class="fa fa-times fa-lg fontWhite"
                                      aria-hidden="true"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => removeEvent(i)}
                                    ></i>
                                  </div>
                                  <div className="col-11 rounded-right bg-light">
                                    <div className="mt-2">
                                      <p className="font-weight-bold text-muted mb-0">
                                        {data?.EventDetails ? (
                                          data?.EventDetails?.Sport?.Name ===
                                          "Football" ? (
                                            <i
                                              class="fa-regular fa-futbol"
                                              style={{ fontSize: "large" }}
                                            ></i>
                                          ) : data?.EventDetails?.Sport
                                              ?.Name === "Basketball" ? (
                                            <i
                                              class="fa-solid fa-basketball"
                                              style={{ fontSize: "large" }}
                                            ></i>
                                          ) : data?.EventDetails?.Sport
                                              ?.Name === "Tennis" ? (
                                            <i
                                              class="fa fa-circle"
                                              style={{ fontSize: "large" }}
                                            ></i>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          <i
                                            class="fa-regular fa-futbol"
                                            style={{ fontSize: "large" }}
                                          ></i>
                                        )}{" "}
                                        <span className="ml-1">
                                          {data?.marketCategory ===
                                            "moneyline" && data?.sign === "home"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[0] || "Team 1"
                                              }`
                                            : data?.marketCategory ===
                                                "moneyline" &&
                                              data?.sign === "draw"
                                            ? "Draw"
                                            : data?.marketCategory ===
                                                "moneyline" &&
                                              data?.sign === "away"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[1] || "Team 2"
                                              }`
                                            : data?.marketCategory ===
                                                "spread" &&
                                              data?.sign === "home"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[0] || "Team 1"
                                              } (${data?.points})`
                                            : data?.marketCategory ===
                                                "spread" &&
                                              data?.sign === "away"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[1] || "Team 2"
                                              } (${data?.points})`
                                            : data?.marketCategory === "total"
                                            ? `${data?.sign} (${data?.points})`
                                            : data?.marketCategory === "dnb" &&
                                              data?.sign === "home"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[0] || "Team 1"
                                              }`
                                            : data?.marketCategory === "dnb" &&
                                              data?.sign === "away"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[1] || "Team 2"
                                              }`
                                            : data?.marketCategory === "dc" &&
                                              data?.sign === "home-draw"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[0] || "Team 1"
                                              } or Draw`
                                            : data?.marketCategory === "dc" &&
                                              data?.sign === "home-away"
                                            ? `${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[0] || "Team 1"
                                              } or ${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[1] || "Team 2"
                                              }`
                                            : data?.marketCategory === "dc" &&
                                              data?.sign === "draw-away"
                                            ? `Draw or ${
                                                data?.EventDetails?.Name.split(
                                                  " - "
                                                )[1] || "Team 2"
                                              }`
                                            : // : data?.marketCategory === "ggng"
                                            // ? `${data?.sign}`
                                            // : ""}
                                            data?.marketCategory === "ggng" &&
                                              data?.sign === "gg"
                                            ? "Goal"
                                            : data?.marketCategory === "ggng" &&
                                              data?.sign === "ng"
                                            ? "No Goal"
                                            : ""}
                                        </span>
                                      </p>

                                      <p className="text-muted fs_12">
                                        {data?.EventDetails
                                          ? `${data?.EventDetails?.Region?.Name} - ${data?.EventDetails?.League?.Name}`
                                          : data?.leagueName}
                                        {/* INTRNATIONAL - CHAMPIONS LEAGUE */}
                                      </p>
                                      <p className="fa-1x text-muted">
                                        {data?.EventDetails
                                          ? data?.EventDetails?.Name
                                          : data?.name}{" "}
                                        -{" "}
                                        {data?.EventDetails
                                          ? format(
                                              parseISO(
                                                data?.EventDetails?.Date
                                              ),
                                              "dd/MM/yyyy HH:mm"
                                            )
                                          : format(
                                              parseISO(data?.date),
                                              "dd/MM/yyyy HH:mm"
                                            )}
                                        {/* Wolverhampton vs Btentford FC - 20/02/2024, 20:30 */}
                                      </p>
                                      {/* <p className="text-muted">1X2</p> */}
                                      <p className="text-muted text-uppercase">
                                        {data?.marketCategory === "moneyline"
                                          ? "1X2"
                                          : data?.marketCategory}
                                      </p>
                                      {/* <p className="h4">1.88</p> */}
                                      <p className="fs-16 text-black">
                                        {data?.odd}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}

                            {CreateSlipType === "Fixed" &&
                              createEvent.map((data, i) => (
                                <div key={i} className="d-flex my-3">
                                  <div className="col-1 rounded-left bg-dark">
                                    <i
                                      class="fa fa-times fa-lg mt-5 pt-3 fontWhite"
                                      aria-hidden="true"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => removeEvent(i)}
                                    ></i>
                                  </div>
                                  <div className="col-11 rounded-right bg-light">
                                    <div className="mt-2">
                                      <p className="font-weight-bold text-muted mb-0">
                                        {data?.sportName === "Football" ? (
                                          <i
                                            class="fa-regular fa-futbol"
                                            style={{ fontSize: "large" }}
                                          ></i>
                                        ) : data?.sportName === "Basketball" ? (
                                          <i
                                            class="fa-solid fa-basketball"
                                            style={{ fontSize: "large" }}
                                          ></i>
                                        ) : data?.sportName === "Tennis" ? (
                                          <i class="fa fa-circle ">
                                            {/* <img
                                              src={TennisIcon}
                                              alt="Tennis"
                                            /> */}
                                          </i>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {/* <span className=" ml-1">Wolverhampton</span>{" "} */}
                                        <span className=" ml-1">
                                          {data?.description}-{data?.date}
                                        </span>{" "}
                                      </p>
                                      <p className="text-muted fs_12">
                                        -{/* INTRNATIONAL - CHAMPIONS LEAGUE */}
                                      </p>
                                      <p className="fa-1x text-muted">
                                        -
                                        {/* Wolverhampton vs Btentford FC - 20/02/2024, 20:30 */}
                                      </p>
                                      {/* <p className="text-muted">1X2</p> */}
                                      <p className="text-muted">-</p>
                                      {/* <p className="h4">1.88</p> */}
                                      <p className="h4">-</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-body p-0">
                          <div
                            className="bg-dark"
                            style={{ minHeight: "220px" }}
                          >
                            <div className="row px-4 pt-3 pb-1">
                              <div className="col-6">
                                <input
                                  type="number"
                                  className={`form-control form-control-sm ${
                                    !TotalBetAmountCurrency.amount &&
                                    AmountCurrencySubmit
                                      ? "border_error"
                                      : ""
                                  }`}
                                  placeholder="Amount"
                                  value={TotalBetAmountCurrency.amount}
                                  onChange={(e) => {
                                    setTotalBetAmountCurrency((prev) => ({
                                      ...prev,
                                      amount: AmountBasedCurrency(
                                        e.target.value
                                      ),
                                    }));
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                  min={0}
                                />
                              </div>

                              <div className="col-4">
                                <select
                                  className={`form-control form-control-sm ${
                                    !TotalBetAmountCurrency.currency &&
                                    AmountCurrencySubmit
                                      ? "border_error"
                                      : ""
                                  }`}
                                  onChange={(e) =>
                                    setTotalBetAmountCurrency((prev) => ({
                                      ...prev,
                                      currency: e.target.value,
                                      amount: AmountBasedCurrency(
                                        TotalBetAmountCurrency.amount,
                                        e.target.value
                                      ),
                                    }))
                                  }
                                  value={TotalBetAmountCurrency.currency}
                                >
                                  <option value={""} selected hidden>
                                    Select Currency
                                  </option>
                                  {currencylist?.map((item, i) => (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn pt-2 text-white"
                                  onClick={() => {
                                    setAmountCurrencySubmit(true);
                                    AddAmountCurrencyFunc();
                                  }}
                                >
                                  <i
                                    class="fa fa-check"
                                    style={{ fontSize: "x-large" }}
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>

                            <div className="row d-inline">
                              {CreateSlipType !== "Fixed" && (
                                <div className="d-flex align-content-between align-items-center justify-content-between px-4">
                                  <h5 className="text-white">Total Odds</h5>
                                  {TotalOddEdit && (
                                    <input
                                      type="number"
                                      placeholder="Total Odd"
                                      className={`form-control form-control-sm col-3 ml-auto`}
                                      value={TotalOdds}
                                      // onChange={(e)=>setTotalOdds(e.target.value)}
                                      onChange={handleUpdateTotalOdds}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  )}
                                  <h5 className="text-white ">
                                    {!TotalOddEdit && TotalOdds}
                                    {!TotalOddEdit && (
                                      <span
                                        className="ml-3 pl-1"
                                        onClick={() => setTotalOddEdit(true)}
                                      >
                                        <i
                                          class="fa fa-pencil cursor"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {TotalOddEdit && (
                                      <span
                                        className="ml-3"
                                        onClick={() => setTotalOddEdit(false)}
                                      >
                                        <i
                                          class="fa fa-check cursor fa-xl"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </h5>
                                </div>
                              )}
                              <div className="d-flex align-content-between justify-content-between px-4 mt-2">
                                <h5 className="text-white">
                                  {CreateSlipType === "Fixed"
                                    ? "Profit/Loss"
                                    : "Total Bet"}
                                </h5>
                                <h5 className="text-white">
                                  {/* 100 €{" "} */} {TotalBet.amount || 0}{" "}
                                  {TotalBet.currency}
                                  <span
                                    className="ml-3"
                                    onClick={() =>
                                      setTotalBetAmountCurrency(TotalBet)
                                    }
                                  >
                                    <i
                                      class="fa fa-pencil cursor"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </h5>
                              </div>
                              {CreateSlipType !== "Fixed" && (
                                <div className="d-flex align-content-between justify-content-between px-4 mr-3 mt-2">
                                  <h5 className="text-white">Potential Win</h5>
                                  <h5>
                                    <span className="h5 fontWhite">
                                      {PotentialWinAmount}
                                      {/* {(
                                        TotalOdds * (TotalBet?.amount || 0)
                                      ).toFixed(3)} */}
                                      {/* {(TotalBet?.amount ? TotalBet?.amount : 0)} */}
                                    </span>{" "}
                                    <span className="mr-3 text-white">
                                      {TotalBet.currency}
                                    </span>
                                    {/* <span className="ml-3 text-white"> */}
                                    {/* <i
                                      class="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i> */}
                                    {/* </span> */}
                                  </h5>
                                </div>
                              )}
                              <div className="mt-2 px-4 d-flex justify-content-between">
                                <button
                                  className="btn btn-outline-secondary btn-sm rounded-pill"
                                  onClick={() => {
                                    HandleHardReset();
                                  }}
                                >
                                  <i class="fa fa-trash" aria-hidden="true"></i>{" "}
                                  Clear Betslip
                                </button>

                                {CreateSlipType !== "Search" && (
                                  <div className="col-4 pr-0">
                                    <select
                                      className={`form-control form-control-sm`}
                                      onChange={(e) =>
                                        setSlipStatus(e.target.value)
                                      }
                                      value={SlipStatus}
                                    >
                                      <option value="" selected hidden>
                                        {" "}
                                        Select Status
                                      </option>
                                      <option value="Pending">Pending</option>
                                      <option value="Win">Win</option>
                                      <option value="Win50%">Win 50%</option>
                                      <option value="Lost">Lost</option>
                                      <option value="Void">Void</option>
                                    </select>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-5 mt-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <span
                            className="add_circle bg-danger"
                            type="button"
                            onClick={() => {
                              setCreateSlipType("");
                              HandleReset();
                            }}
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </span>
                          <span className="add_circle mx-2 bg-success">
                            <button
                              type="submit"
                              className="btn text-white"
                              onClick={CreateNewSlip}
                            >
                              <i class="fa-solid fa-check"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-5 mt-1">
                      <div className="d-flex justify-content-center">
                        <span
                          className="add_circle bg-danger"
                          type="button"
                          onClick={() => {
                            setCreateSlipType("");
                            HandleReset();
                          }}
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </span>
                        <span className="add_circle mx-2 bg-success">
                          <button
                            type="submit"
                            className="btn text-white"
                            onClick={CreateNewSlip}
                          >
                            <i class="fa-solid fa-check"></i>
                          </button>
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="LoaderSpinner">
              <MoonLoader
                color={"#003bff"}
                loading={loading}
                speedMultiplier={0.6}
                size={50}
              />
              <div>Loading... Please wait.</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
}
