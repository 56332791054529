/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { GetService, PostService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import { format, parseISO, set } from "date-fns";
import Dropdown from "react-bootstrap/Dropdown";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  TooltipComponent2,
  TooltipComponent3,
} from "../../Layout/Comman_Tooltip/Tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// / CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import TablePagination from "@mui/material/TablePagination";
import { formatAmount, getCurrencyListing } from "../../../../Helper/utils";
import { toast } from "react-toastify";

export default function Slips() {
  const [sliplisting, setsliplisting] = useState([]);
  const [currencylist, setcurrencylist] = useState();
  const [playerlist, setplayerlist] = useState();
  const [referencelist, setreferencelist] = useState();
  const [groupData, setgroupData] = useState();
  const [profilolist, setprofilolist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [widgets, setWidgets] = useState();
  const [S_No_Count, setCount] = useState(1);
  const [userLimit, setUserLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [DataObj, setDataObj] = useState("");
  const [submit, setsubmit] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [searchDate, setsearchDate] = useState([]);
  const [searchCurrency, setsearchCurrency] = useState("EUR");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [apicall, setapicall] = useState(false);

  const [selectedSlip, setSelectedSlip] = useState();

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.status) {
        errors.status = "status is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      const data = { status: values?.status, id: DataObj?.id };
      PostService(API_URL.CHANGE_STATUS_SLIP, data).then(
        (res) => {
          if (res?.data.status) {
            document.getElementById("closeModal").click();
            handleReset();
            showSuccessAlert(res?.data?.message, getSLIPlisting);
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  function Setdata(values) {
    formik.setValues({
      status: values?.status,
    });
    setDataObj(values);
  }

  function updateStatus(dataa, status) {
    Swal.fire({
      title: "Change Status!",
      text: "Are you sure you want to change the slip status",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF5733",
      cancelButtonText: "Cancel",
      confirmButtonText: "Change",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const data = { status: status, id: dataa?.id };
        PostService(API_URL.CHANGE_STATUS_SLIP, data).then(
          (res) => {
            if (res?.data.status) {
              setLoading(false);
              handleReset();
              showSuccessAlert(res?.data?.message, getSLIPlisting);
            } else {
              setLoading(false);
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            showErrorAlert(err.data?.message);
          }
        );
      }
    });
  }

  function getSLIPlisting() {
    const data = {
      sport_Id: "",
      group_id: "",
      type: "",
    };
    setLoading(true);
    // const route = `${API_URL.SLIP_LISTING}?sport_Id=${data?.sport_Id}&group_id=${data?.group_id}`;
    const route = `${
      API_URL.SLIP_LISTING
    }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
      searchValue
    )}&from=${searchDate[0] || ""}&to=${
      searchDate[1] || ""
    }&currency=${searchCurrency}`;
    GetService(route).then((res) => {
      console.log(res);
      setLoading(false);
      if (res?.data?.status) {
        setsliplisting(res?.data?.data?.list || []);
        setTotalPages(res.data?.data?.totalPages || 0);
        setTotal(res?.data?.data?.totalItems || 0);
        setWidgets(res?.data?.data?.slipttotal);
        setapicall(true);
      }
    });
  }

  function openDeleteModal(slip) {
    setSelectedSlip(slip);
  }

  function getUpdatedStatusSLIPlisting() {
    setLoading(true);
    // const route = `${API_URL.SLIP_LISTING}?page=${currentPage}&size=${userLimit}&currency=${searchCurrency}&change_status=true`;
    const route = `${API_URL.GET_UPDATED_SLIP_STATUS}`;
    GetService(route).then((res) => {
      // setLoading(false);
      console.log(res);
      if (res?.data?.status && res?.data?.message === "statusChange") {
        getSLIPlisting();

        // setTimeout(() => {

        //   // getSLIPlisting()
        // }, 1000);
        // setsliplisting(res?.data?.data?.list);
        // setTotalPages(res.data?.data?.totalPages);
        // setTotal(res?.data?.data?.totalItems);
      } else {
        setLoading(false);
        showErrorAlert(res.data?.message);
      }
    });
  }

  function getCurrencylisting() {
    getCurrencyListing()
      .then((res) => {
        setcurrencylist(res);
      })
      .catch((err) => {
        console.error("Error fetching currency list:", err);
        toast.error("Error fetching currency list");
      });
  }

  function getplayerlisting() {
    GetService(API_URL.PLAYERS_LISTING).then((res) => {
      if (res?.status) {
        console.log(res?.data?.data?.list);
        setplayerlist(res?.data?.data?.list);
      }
    });
  }
  function getreferencelisting() {
    GetService(API_URL.GET_REFERENCE).then((res) => {
      if (res?.status) {
        setreferencelist(res?.data?.data?.list);
      }
    });
  }
  function getprofilelisting() {
    GetService(API_URL.GET_PROFILO).then((res) => {
      if (res?.status) {
        setprofilolist(res?.data?.data?.list);
      }
    });
  }
  function getgrouplisting() {
    GetService(API_URL.GROUP_LISTING).then((res) => {
      if (res?.status) {
        setgroupData(res?.data?.data?.list);
      }
    });
  }
  const handleOptionSelect = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };

  useEffect(() => {
    // getSLIPlisting();
    getCurrencylisting();
    // getplayerlisting();
    // getreferencelisting();
    // getprofilelisting();
    // getgrouplisting();
  }, []);

  // useEffect(() => {
  //   getSLIPlisting();
  // }, [selectedOptions]);

  useEffect(() => {
    getSLIPlisting();
  }, [currentPage, searchValue, searchDate, searchCurrency, userLimit]);

  const filterOptions = [
    { name: "Player", Icon: "px-2 fa-solid fa-user", dropOptions: playerlist },
    {
      name: "Referente",
      Icon: "px-2 fa-solid fa-user",
      dropOptions: referencelist,
    },
    {
      name: "Gruppo",
      Icon: "px-2 fa-solid fa-users-viewfinder",
      dropOptions: groupData,
    },
  ];

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = sliplisting?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const handleReset = () => {
    formik.resetForm();
    setsubmit(false);
  };

  const getBtnClass = (status) => {
    // Add logic to determine the corresponding badge class based on the status
    switch (status) {
      case "Pending":
        return "btn btn-sm btn-warning align-items-center d-flex px-2";
      case "Win":
        return "btn btn-sm btn-success align-items-center d-flex px-2";
      case "Win50%":
        return "btn btn-sm btn-secondary align-items-center d-flex px-2";
      case "Lost":
        return "btn btn-sm btn-danger align-items-center d-flex px-2";
      case "Lost50%":
        return "btn btn-sm btn-secondary align-items-center d-flex px-2";
      case "Void":
        return "btn btn-sm btn-info align-items-center d-flex px-2";
      case "Error":
        return "btn btn-sm btn-danger align-items-center d-flex px-2";
      default:
        return "";
    }
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    // console.log(dateRange);
    if (apicall) {
      const extractedDates = dateRange.map((dateString) => {
        if (!dateString) {
          return null;
        }
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      });
      // console.log(extractedDates);
      if (extractedDates[0] && !extractedDates[1]) {
        return;
      } else {
        setsearchDate(extractedDates);
      }
    }
  }, [dateRange]);

  const ExportToCSV = () => {
    const ExportArray = [];
    sliplisting.map((data) => {
      let slipid = encodeURIComponent(
        data?.slip
          ? "slip#" + data?.slip
          : "slip#" + data?.slip_id.replace("slip#", "")
      );

      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd-MM-yyyy HH:mm")
          : "NO",
        Slip_Id: slipid.replace("%23", "-"),

        Source: data?.slip_type === "Search" ? "Auto" : "Manual" || "-",
        Tipology:
          data?.type === "single" && data?.slip_type !== "Fixed"
            ? "Single"
            : data?.type === "parlay" && data?.slip_type !== "Fixed"
            ? "Parlay"
            : data?.slip_type === "Fixed"
            ? "Fixed"
            : "-",
        Group: data?.main_group?.name || "-",
        Amount: data?.Value,
        Currency: data?.Currency,
        Status:
          data?.status === "Pending"
            ? "Pending"
            : data?.status === "Win"
            ? "Win"
            : data?.status === "Lost"
            ? "Lost"
            : data?.status === "Void"
            ? "Void"
            : data?.status === "Win50%"
            ? "Win 50%"
            : data?.status === "Lost50%"
            ? "Lost 50%"
            : data?.status === "Error"
            ? "Error"
            : data?.status === "payed"
            ? "Payed"
            : "-",
        "Profit/Loss": data?.profit_loss
          ? `${data?.profit_loss} ${data?.Currency}`
          : "-",
        "Payed/Collected":
          data?.role === "refrent"
            ? "Referent"
            : data?.role === "player"
            ? "Player"
            : "-",
        Connected: data?.attach_group?.name || "-",
      });
    });

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Log CSV content before initiating download
    console.log(csvContent);

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Slips.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const DeleteFunc = async () => {
    console.log("first");
    if (selectedSlip.status !== "Pending") {
      const data = { status: "Pending", id: selectedSlip?.id };
      try {
        const res = await PostService(API_URL.CHANGE_STATUS_SLIP, data);
        if (res?.data.status) {
          setLoading(false);
          handleReset();
          showSuccessAlert(res?.data?.message, getSLIPlisting);
        } else {
          setLoading(false);
          showErrorAlert(res.data?.message);
        }
      } catch (err) {
        showErrorAlert(err.data?.message);
      }
    }

    PostService(API_URL.DELETE_SLIP, { id: selectedSlip.id }).then(
      (res) => {
        if (res?.data.status) {
          document.getElementById("closeDeleteModal").click();
          handleReset();
          showSuccessAlert(res?.data?.message, getSLIPlisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Slips List</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link className="breadcrumb-item active">Slips List</Link>
                </ol>
              </span>
            </div>
            <div className="col-6 pr-5">
              <div className="d-flex justify-content-end">
                <NavLink to={"/panel/create_slip"}>
                  <div
                    className="btn  bg-secondprimary rounded-sm text-white"
                    style={{ minWidth: "180px" }}
                  >
                    <i className="fa-solid fa-plus mr-1"></i>
                    New Slip
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="row my-2">
                    <div
                      className="align-items-center badge badge-secondary d-flex justify-content-between mx-2 px-3 py-0 pt-1"
                      style={{ width: "14%" }}
                    >
                      <input
                        type="date"
                        className="position-absolute"
                        style={{ width: "15%", opacity: "0" }}
                      />
                      <p>Periodo</p>
                      <i class="fa-solid fa-calendar-days"></i>
                    </div>
                    {filterOptions &&
                      filterOptions.map((item, i) => (
                        <Dropdown className="border-0">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="align-items-center badge badge-secondary d-flex justify-content-between mx-2 px-3 py-0 pt-1 border-0"
                          >
                            <div className="align-items-baseline d-flex">
                              <p className="pr-5">{item?.name}</p>
                              <i class={item?.Icon}></i>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.dropOptions &&
                              item?.dropOptions.map((option, i) => (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => handleOptionSelect(option)}
                                >
                                  {option?.player_name || option?.name}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ))}
                    <div
                      className="align-items-center badge badge-secondary d-flex justify-content-between mx-2 px-3 py-0 pt-1"
                      style={{ width: "13%" }}
                    >
                      <p>Transazione</p>
                      <i class="fa-solid fa-arrow-right-arrow-left"></i>
                    </div>
                    <div
                      className="d-flex justify-content-end align-items-center"
                      style={{ width: "9%" }}
                    >
                      <i
                        style={{ cursor: "pointer" }}
                        className="fa fa-refresh fs-30"
                        aria-hidden="true"
                        onClick={getUpdatedStatusSLIPlisting}
                      ></i>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="d-flex flex-wrap w-100">
                      {selectedOptions &&
                        selectedOptions.map((item, i) => (
                          <div
                            className="align-items-center badge badge-primary d-flex justify-content-between mx-2 px-3 py-0 pt-1 my-1"
                            style={{ width: "18%" }}
                          >
                            <p>{item?.name || item?.player_name}</p>
                            <i
                              class="fa-solid fa-circle-xmark"
                              onClick={() => handleRemoveOption(i)}
                            ></i>
                          </div>
                        ))}
                    </div>
                  </div> */}

                  <div className="row mt-2">
                    <div className="col-md-4 ">
                      <label htmlFor="search-input" className="search-label">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </label>
                      <input
                        type="search"
                        name=""
                        id="search-input"
                        className="form-control pl-5 form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setsearchValue(e.target.value)}
                      />
                    </div>
                    <div className="col-md-3 pr-0">
                      <DatePicker
                        className="form-control form-control-sm"
                        placeholderText="Select Date range"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          console.log(update);
                          setDateRange(update);
                        }}
                        isClearable={true}
                      />
                    </div>

                    <div className="col-md-2 pl-0">
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => setsearchCurrency(e.target.value)}
                        value={searchCurrency}
                      >
                        <option value={""}>Select Currency</option>
                        {currencylist?.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-1 d-flex justify-content-end align-items-center">
                      <i
                        style={{ cursor: "pointer" }}
                        className="fa fa-refresh fs-30"
                        aria-hidden="true"
                        onClick={getUpdatedStatusSLIPlisting}
                      ></i>
                    </div>

                    <div className="col-md-2">
                      <button
                        className={`badge badge-outline-primary fa-1x rounded float-right ${
                          !sliplisting.length > 0 ? "disabled" : ""
                        }`}
                        onClick={ExportToCSV}
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-4">
                      <div className="card groupPersonalCard groupPersonalCard_1 rounded-lg">
                        <div className="card-body py-2">
                          <div className="d-flex justify-content-between">
                            <h6 className="color fs-16 mb-0">Balance</h6>
                            <i className="fa-solid fa-chart-simple fs-16"></i>
                          </div>
                          <h2 className="my-2 color">
                            {0} <span className="fs-16">{searchCurrency}</span>
                          </h2>
                          {/* <p className="mb-0  font-weight-bold">
                            <span className="text-success">5.20%</span> &nbsp;
                            Last 7 days
                          </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card d-flex groupPersonalCard color groupPersonalCard_2 rounded-lg">
                        <div className="card-body py-2">
                          <div className="d-flex justify-content-between">
                            <h6 className="mb-0 color fs-16">Turnover</h6>
                            <i class="fa-solid fa-money-bills fs-16"></i>
                          </div>

                          <h2 className="my-2 color">
                            {searchCurrency && widgets?.totalValue
                              ? formatAmount(widgets.totalValue, searchCurrency)
                              : formatAmount(0, searchCurrency)}
                          </h2>

                          {/* <p className="mb-0  font-weight-bold">
                            <span className="text-success">5.20%</span> &nbsp;
                            Last 7 days
                          </p> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card groupPersonalCard groupPersonalCard_3 rounded-lg">
                        <div className="card-body py-2">
                          <div className="d-flex justify-content-between">
                            <h6 className="mb-0 color fs-16">Profit / loss</h6>
                            <i class="fa-solid fa-sack-xmark fs-16"></i>
                          </div>

                          <h2 className="my-2 color">
                            <span
                              className={`${
                                searchCurrency && widgets?.totalprofitloss > 0
                                  ? "text-success"
                                  : searchCurrency &&
                                    widgets?.totalprofitloss < 0
                                  ? "text-danger"
                                  : searchCurrency &&
                                    widgets?.totalprofitloss === 0
                                  ? "text-secondary"
                                  : ""
                              }`}
                            >
                              {searchCurrency && widgets?.totalprofitloss
                                ? formatAmount(
                                    widgets.totalprofitloss,
                                    searchCurrency
                                  )
                                : formatAmount(0, searchCurrency)}
                            </span>
                          </h2>

                          {/* <p className="mb-0  font-weight-bold">
                            <span className="text-success">5.20%</span> &nbsp;
                            Last 7 days
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row px-3">
                    <div
                      class="table-responsive mt-4 pb-2"
                      style={{
                        minHeight: "220px",
                      }}
                    >
                      {!loading && (
                        <table class="table  dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              {/* <th>S.No</th> */}
                              <th
                                className={getHeaderClassName("createdAt")}
                                onClick={() => handleSort("createdAt")}
                              >
                                Date
                              </th>
                              <th className="px-2">Dir</th>
                              <th
                                className="px-2"
                                // className={getHeaderClassName("id")}
                                // onClick={() => handleSort("id")}
                              >
                                Slip ID
                              </th>
                              <th
                                className="px-2"
                                // className={getHeaderClassName("slip_type")}
                                // onClick={() => handleSort("slip_type")}
                              >
                                Source
                              </th>

                              <th
                                className="px-2"
                                // className={getHeaderClassName("type")}
                                // onClick={() => handleSort("type")}
                              >
                                Tipology
                              </th>
                              <th className="px-2">Group</th>

                              <th
                                className={`${getHeaderClassName(
                                  "Value"
                                )} pr-4`}
                                onClick={() => handleSort("Value")}
                              >
                                Amount
                              </th>
                              <th
                                className="px-2"
                                // className={getHeaderClassName("status")}
                                // onClick={() => handleSort("status")}
                              >
                                Status
                              </th>
                              <th
                                className="px-2"
                                // className={getHeaderClassName("group")}
                                // onClick={() => handleSort("group")}
                              >
                                Profit/Loss
                              </th>
                              <th className="px-2">Payed/Collected</th>

                              <th className="px-3">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sliplisting &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  {/* <td>{i + currentPage * userLimit + 1}</td> */}
                                  <td>
                                    {item?.createdAt
                                      ? format(
                                          parseISO(item?.createdAt),
                                          "dd-MM-yyyy HH:mm"
                                        )
                                      : "NO"}
                                  </td>
                                  <td>
                                    {item?.status === "Pending" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "Win" ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↗
                                      </span>
                                    ) : item?.status === "Lost" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "Lost50%" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : item?.status === "Void" ? (
                                      <span
                                        style={{
                                          color: "primary",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ...
                                      </span>
                                    ) : item?.status === "Win50%" ? (
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↗
                                      </span>
                                    ) : item?.status === "Error" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "large",
                                        }}
                                      >
                                        {" "}
                                        ↘
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td>
                                    <Link to={`/panel/view_slip/${item?.id}`}>
                                      <TooltipComponent2
                                        data={
                                          item?.slip
                                            ? item?.slip
                                            : item?.slip_id
                                        }
                                      ></TooltipComponent2>
                                    </Link>
                                  </td>

                                  <td>
                                    {item?.slip_type === "Search"
                                      ? "Auto"
                                      : "Manual" || "NO"}
                                  </td>

                                  <td>
                                    {item?.type === "single" &&
                                    item?.slip_type !== "Fixed" ? (
                                      <span className="badge badge-outline-info">
                                        Single
                                      </span>
                                    ) : item?.type === "parlay" &&
                                      item?.slip_type !== "Fixed" ? (
                                      <span className="badge badge-outline-info">
                                        Parlay
                                      </span>
                                    ) : item?.slip_type === "Fixed" ? (
                                      <span className="badge badge-outline-danger">
                                        Fixed
                                      </span>
                                    ) : (
                                      "NO"
                                    )}
                                  </td>

                                  <td>
                                    <span
                                      className={`mr-1 ${
                                        item?.main_group?.tag === "Passivity"
                                          ? "badge-dot-rd"
                                          : item?.main_group?.tag === "Activity"
                                          ? "badge-dot-gr"
                                          : ""
                                      }`}
                                    ></span>{" "}
                                    {item?.main_group?.name || "NO"}
                                  </td>

                                  <td>
                                    {formatAmount(item?.Value, item?.Currency)}
                                  </td>
                                  <td>
                                    <div className="btn-group">
                                      {item?.status === "Pending" ? (
                                        <span
                                          className={`${getBtnClass(
                                            item?.status
                                          )}`}
                                        >
                                          Pending
                                        </span>
                                      ) : item?.status === "Win" ? (
                                        <span
                                          className={`${getBtnClass(
                                            item?.status
                                          )}`}
                                        >
                                          Win
                                        </span>
                                      ) : item?.status === "Lost" ? (
                                        <span
                                          className={`${getBtnClass(
                                            item?.status
                                          )}`}
                                        >
                                          Lost
                                        </span>
                                      ) : item?.status === "Void" ? (
                                        <span
                                          className={`${getBtnClass(
                                            item?.status
                                          )}`}
                                        >
                                          Void
                                        </span>
                                      ) : item?.status === "Win50%" ? (
                                        <span
                                          className={`${getBtnClass(
                                            item?.status
                                          )}`}
                                        >
                                          Win 50%
                                        </span>
                                      ) : item?.status === "Lost50%" ? (
                                        <span
                                          className={`${getBtnClass(
                                            item?.status
                                          )}`}
                                        >
                                          Lost 50%
                                        </span>
                                      ) : item?.status === "Error" ? (
                                        <span
                                          className={`${getBtnClass(
                                            item?.status
                                          )}`}
                                        >
                                          Error
                                        </span>
                                      ) : (
                                        ""
                                      )}

                                      <button
                                        type="button"
                                        className={`dropdown-toggle dropdown-toggle-split ${getBtnClass(
                                          item?.status
                                        )}`}
                                        id="dropdownMenuSplitButton1"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></button>
                                      {/* code to disabled the btn after status change ---------> ↓  ${
                                          item?.status !== "Pending"
                                            ? "disabled"
                                         : ""}   */}

                                      <div
                                        className="dropdown-menu bg"
                                        aria-labelledby="dropdownMenuSplitButton1"
                                        style={{
                                          border: "1px solid #c4c4c5",
                                          borderRadius: "0.95rem",
                                        }}
                                      >
                                        {[
                                          "Pending",
                                          "Win",
                                          "Win50%",
                                          "Lost",
                                          "Lost50%",
                                          "Void",
                                        ].map((status, index) => (
                                          <a
                                            key={index}
                                            className={`dropdown-item group-item ${
                                              item?.status === status
                                                ? "bg-primary fontWhite disabled"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              updateStatus(item, status)
                                            }
                                          >
                                            {status}
                                          </a>
                                        ))}
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td>{item?.attach_group?.name || "NO"}</td> */}
                                  <td>
                                    <span
                                      className={`font-weight-500 ${
                                        item?.profit_loss > 0
                                          ? "text-success"
                                          : item?.profit_loss < 0
                                          ? "text-danger"
                                          : item?.profit_loss === 0
                                          ? "text-secondary"
                                          : ""
                                      }`}
                                    >
                                      {formatAmount(
                                        item?.profit_loss,
                                        item?.Currency
                                      )}
                                    </span>{" "}
                                  </td>
                                  {/* <td>
                                    {item?.role === "refrent" ? (
                                      <span className="badge badge-info">
                                        Referent
                                      </span>
                                    ) : item?.role === "player" ? (
                                      <span className="badge badge-primary">
                                        Player
                                      </span>
                                    ) : (
                                      "NO"
                                    )}
                                  </td> */}

                                  <td>
                                    <TooltipComponent3
                                      data={item}
                                    ></TooltipComponent3>
                                  </td>

                                  {/* <td> */}
                                  {/* <span
                                      data-toggle="modal"
                                      data-target="#AddModal"
                                      title="Edit"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      // style={{
                                      //   cursor:
                                      //     "pointer",
                                      //     ...(item?.win_status !== "Pending"
                                      //       ? { pointerEvents: "none" }
                                      //       : {}),
                                      // }}
                                      // className={`mx-2 ${item?.win_status !== "Pend  ing" ? "text-muted" : ""}`}
                                      onClick={() => Setdata(item)}
                                      className="mx-2"
                                    >
                                      <i class="fa-solid fa-pen"></i>
                                    </span> */}
                                  {/* </td> */}

                                  <td>
                                    <span
                                      data-toggle="modal"
                                      data-target="#DeleteModal"
                                      onClick={() => openDeleteModal(item)}
                                    >
                                      <i
                                        class="fa fa-trash text-danger cursor"
                                        aria-hidden="true"
                                        title="Delete"
                                      ></i>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {sliplisting && sliplisting.length === 0 && (
                              <tr>
                                <td colSpan="11" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                              <td colSpan={11}>
                                <div className="d-flex justify-content-end">
                                  <NavLink to={"/panel/create_slip"}>
                                    <div className="add_circle bg-primary">
                                      <i class="fa-solid fa-plus"></i>
                                    </div>
                                  </NavLink>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      )}
                    </div>

                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-4 ml-auto">
                      {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}
                      <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Update status MODAL-------------------- / */}
      <div
        className="modal fade"
        id="AddModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-body py-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="row my-2">
                      <div className="col-12">
                        <select
                          className={`form-control ${
                            formik.errors.status && submit ? "border_error" : ""
                          }`}
                          name="status"
                          onChange={formik.handleChange}
                          value={formik.values?.status}
                        >
                          <option value="" hidden>
                            Change Slip Status
                          </option>
                          <option value="pending">Pending</option>
                          <option value="win">Win</option>
                          <option value="win50">Win 50%</option>
                          <option value="lose">Lose</option>
                          <option value="void">Void</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="d-flex justify-content-end">
                      <span
                        className="add_circle bg-danger"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="closeModal"
                        onClick={handleReset}
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </span>
                      <span className="add_circle mx-2 bg-success">
                        <button
                          type="submit"
                          className="btn text-white"
                          onClick={() => setsubmit(true)}
                        >
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* / Add MODAL- End ------------------- / */}

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ borderRadius: "20px" }}>
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Delete Slip !
              </h3>
              <button
                type="button"
                className="close pb-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-times" aria-hidden="true"></i>
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <div className="modal-body">
              <h4 className="font-weight-light">
                {" "}
                Are you sure you want to delete this slip?
              </h4>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary d-none"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger rounded-lg"
                onClick={DeleteFunc}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}
