import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import {
  GetService,
  PostFormData,
  PostService,
} from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import { useFormik } from "formik";
import { ImageURL } from "../../../../Environment/Environment";
import IconUser from "../../../../assets/img/IconUser.png";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";

export default function AddNewCollaborator() {
  const navigate = useNavigate();
  const ID = useParams();

  const [loading, setLoading] = useState(false);
  const [formsubmit, setformsubmit] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [referencelist, setreferencelist] = useState();
  const resetStateRef = useRef(false);
  const inputdata = useRef("");
  const component = useRef("player");

  useEffect(() => {
    getreferencelisting();
  }, []);

  useEffect(() => {
    if (ID?.id) {
      setLoading(true);
      PostService(API_URL.COLLABORATORS_DETAILS, { id: ID?.id }).then(
        (res) => {
          if (res?.data?.status) {
            Setdata(res?.data?.data);
            setselectedState(res?.data?.data);
          } else {
            showErrorAlert(res.data?.message);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          showErrorAlert(err.data?.message);
        }
      );
    }
  }, [ID?.id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      status: 1,
      phone: "",
      ref_id: "",
      description: "",
      username: "",
      password: "",
      confirm_password: "",
    },
    validate: (values) => {
      console.log(values);
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }

      // if (!values.email) {
      //   errors.email = "Email is required";
      // } else if (
      //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      // ) {
      //   errors.email = "Invalid email address";
      // }

      if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = "Invalid email address";
        }
      }

      // if (!values.phone) {
      //   errors.phone = "Phone is required";
      // } else if (CheckTelLength(values.phone)) {
      //   errors.phone = "Phone length";
      // }
      if (values.phone) {
        if (CheckTelLength(values.phone)) {
          errors.phone = "Phone length";
        }
      }

      if (!values.ref_id) {
        errors.ref_id = "ref_id is required";
      }

      if (!values.description) {
        errors.description = "description is required";
      }

      if (!values.username) {
        errors.username = "username is required";
      }

      if (!selectedState && !values.password) {
        errors.password = "Password is required";
      }

      if (!selectedState) {
        if (!values.confirm_password) {
          errors.confirm_password = "Confirm Password is required";
        } else if (values.confirm_password !== values.password) {
          errors.confirm_password = "Password mismatch";
        }
      } else {
        if (values.confirm_password !== values.password) {
          errors.confirm_password = "Password mismatch";
        }
      }

      return errors;
    },

    onSubmit: (values) => {
      console.log(values);
      setformsubmit(true);
      if (!formik.isValid) {
        return;
      }
      setLoading(true);
      const route = selectedState
        ? API_URL.UPDATE_COLLABORATORS
        : API_URL.CREATE_COLLABORATORS;

      const formdata = new FormData();
      if (selectedState) {
        formdata.append("id", selectedState?.id);
      }
      formdata.append("name", values.name);
      formdata.append("email", values.email);
      formdata.append("status", values.status);
      formdata.append("phone", values.phone);
      formdata.append("refrents", values.ref_id);
      formdata.append("description", values.description);
      formdata.append("username", values.username);
      formdata.append("password", values.password);
      formdata.append("confirm_password", values.confirm_password);
      formdata.append("image", selectedFile || "");

      PostFormData(route, formdata).then(
        (res) => {
          setLoading(false);
          if (res?.data?.status) {
            showSuccessAlert(res?.data?.message, NavigateUser);
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          setLoading(false);
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  const NavigateUser = () => {
    navigate("/panel/collaborators");
  };

  function Setdata(values) {
    formik.setValues({
      name: values?.name,
      email: values?.email,
      status: values?.status,
      phone: values?.phone,
      ref_id: values?.refrents_detail[0]?.refrents?.id,
      description: values?.description,
      username: values?.username,
      password: "",
      confirm_password: "",
    });

    setselectedState(values);
    setformsubmit(false);

    setselectedFile(values?.image || "");
    setSelectedImage(ImageURL + values?.image || IconUser);
  }

  const handlePhoneChange = (value) => {
    console.log(value);
    if (value && value.trim() !== "") {
      formik.setFieldValue("phone", value);
    }
  };

  function CheckTelLength(value) {
    // console.log(value)
    const phoneNumberObject = parsePhoneNumber(value);
    const nsn = phoneNumberObject ? phoneNumberObject.nationalNumber : "";

    // Check the length of the NSN
    const nsnLength = nsn.length;
    // console.log(nsn)
    // console.log(nsnLength)

    if (nsnLength >= 8 && nsnLength <= 15) {
      return false;
    } else {
      return true;
    }
  }

  const handleReset = () => {
    formik.resetForm();
    setformsubmit(false);
  };

  const [selectedFile, setselectedFile] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  function getreferencelisting() {
    GetService(API_URL.GET_REFERENCE).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setreferencelist(res?.data?.data?.list);
      }
    });
  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30 text-secondprimary">
                {selectedState ? "Edit" : "New"} Collaborator
              </h3>
              <span>
                <ol className="breadcrumb ">
                  <Link to="/panel/dashboard" className="breadcrumb-item">
                    Home
                  </Link>
                  <Link to="/panel/collaborators" className="breadcrumb-item">
                    Collaborators
                  </Link>
                  <span className="breadcrumb-item active">
                    New Collaborator
                  </span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row px-3">
                      <div className="col-5 ">
                        <div className="p-3 d-flex flex-column groupPersonalCard rounded p-2 py-3">
                          <div className="d-flex justify-content-center align-items-center">
                            <div
                              style={{ height: "98px", width: "98px" }}
                              className="overflow-hidden rounded-circle mr-3"
                            >
                              <label htmlFor="fileInput">
                                <span
                                  style={{
                                    position: "absolute",
                                    left: "25%",
                                    top: "10px",
                                  }}
                                >
                                  <i className="fa-solid fa-square-pen cursor"></i>
                                </span>{" "}
                              </label>
                              <img
                                src={selectedImage || IconUser}
                                alt="profile"
                                className="img-md h-100 w-100"
                                onError={(e) => {
                                  e.target.src = IconUser;
                                }}
                              />
                              <input
                                type="file"
                                id="fileInput"
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(e)}
                              />
                            </div>

                            <div>
                              <input
                                type="text"
                                className={`form-control form-control-sm ${
                                  formik.errors.name && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                placeholder="Name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                              ></input>
                            </div>
                          </div>{" "}
                          <hr className="groupPersonalCard m-2" />
                          <div className="d-flex mt-2">
                            <div className="col-7 pl-0">
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className={`form-control form-control-sm ${
                                  formik.errors.email && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                placeholder="Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                              ></input>
                            </div>

                            <div className="col-5">
                              <label className="text-label">Status</label>
                              <select
                                className={`form-control form-control-sm ${
                                  formik.errors.status && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name="status"
                                value={formik.values.status}
                              >
                                <option value="" selected hidden>
                                  Status
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-7 pl-0 mt-2">
                            <label className="text-label">Telephone</label>
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="IT"
                              value={formik.values.phone}
                              onChange={handlePhoneChange}
                              className={`form-control form-control-sm ${
                                formik.errors.phone && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              name="phone"
                            />
                          </div>
                          <div className="col-7 pl-0 mt-2">
                            <label className="text-label">Referent</label>
                            <select
                              onChange={formik.handleChange}
                              value={formik.values.ref_id}
                              name="ref_id"
                              className={`form-control form-control-sm ${
                                formik.errors.ref_id && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                            >
                              <option value={""} selected hidden>
                                Select Refrent
                              </option>
                              {referencelist &&
                                referencelist?.map((item, i) => (
                                  <option value={item?.id} key={i}>
                                    {item?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-12 mt-5 d-flex p-0">
                            <div className="col-6 p-0 pr-2">
                              {!selectedState && (
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  onClick={handleReset}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>{" "}
                                  Undo
                                </button>
                              )}
                              {selectedState && (
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  onClick={NavigateUser}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>{" "}
                                  Close
                                </button>
                              )}
                            </div>
                            <div className="col-6 p-0 pl-2">
                              <button
                                type="submit"
                                className="btn btn-sm bg-primary text-white w-100 px-2 rounded"
                                onClick={() => {
                                  setformsubmit(true);
                                }}
                              >
                                <i class="fa fa-check" aria-hidden="true"></i>{" "}
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-7 pr-0 groupPersonalCard rounded p-2">
                        <div className="">
                          <div className="mt-1">
                            <label className="text-label">Description</label>

                            <textarea
                              name="description"
                              rows="2"
                              className={`form-control  ${
                                formik.errors.description && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              placeholder="Insert Description"
                            ></textarea>
                            <hr className="groupPersonalCard mx-0 my-2" />
                          </div>

                          <div className="col-12 p-0 my-3">
                            <div
                              className="card pl-1"
                              style={{ border: "none" }}
                            >
                              <div className="card-body groupPersonalCard px-2 rounded">
                                <label className="text-label">
                                  Account Connected
                                </label>

                                <div className="d-flex align-items-baseline pl-0 my-3">
                                  <label className="fs_12 p-0 font-weight-medium">
                                    Username:
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control form-control-sm ml-1 mr-3 ${
                                      formik.errors.username && formsubmit
                                        ? "border_error"
                                        : ""
                                    }`}
                                    placeholder="enter email address"
                                    name="username"
                                    value={formik.values.username}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                  ></input>
                                </div>

                                <div className="d-flex">
                                  <div className="col-5 d-flex align-items-baseline pl-0">
                                    <label className="fs_12 p-0 font-weight-medium">
                                      Password:
                                    </label>
                                    <input
                                      type="text"
                                      className={`form-control form-control-sm ml-2 ${
                                        !selectedState &&
                                        formik.errors.password &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                      placeholder="Password"
                                      name="password"
                                      value={formik.values.password}
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                    ></input>
                                  </div>
                                  <div className="col-7 d-flex align-items-baseline">
                                    <label className="fs_12 col-5 p-0 font-weight-medium">
                                      Confirm Password:
                                    </label>
                                    <input
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        !selectedState &&
                                        formik.errors.confirm_password &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                      onBlur={formik.handleBlur}
                                      placeholder="Confirm Password"
                                      name="confirm_password"
                                      value={formik.values.confirm_password}
                                      onChange={formik.handleChange}
                                      // disabled={DisabledField}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  {loading ? (
                    <div className="LoaderSpinner">
                      <MoonLoader
                        color={"#003bff"}
                        loading={loading}
                        speedMultiplier={0.6}
                        size={50}
                      />
                      <div>Loading... Please wait.</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
