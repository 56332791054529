import React, { useEffect, useRef } from "react";
import "../PerformanceCard/PerformanceCard.css";
import Chart from "chart.js/auto";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

Chart.register(DataLabelsPlugin);

function formatAmount(amount, currency) {
  return `${amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} ${currency}`;
}

export default function PerformanceCard({
  activityData,
  passivityData,
  combinedData,
}) {
  const activityChartRef = useRef();
  const passivityChartRef = useRef();
  const chartInstancesRef = useRef({});

  useEffect(() => {
    if (chartInstancesRef.current.activity) {
      chartInstancesRef.current.activity.destroy();
    }
    if (chartInstancesRef.current.passivity) {
      chartInstancesRef.current.passivity.destroy();
    }

    // Skip rendering if no data
    if (!activityData || !passivityData || !combinedData) return;

    const activityCtx = activityChartRef.current.getContext("2d");
    const passivityCtx = passivityChartRef.current.getContext("2d");

    // Transform the data for charts
    const currencies = Object.keys(activityData || {}).filter(
      (curr) =>
        curr !== "grand_total" &&
        (activityData[curr] !== 0 ||
          passivityData[curr] !== 0 ||
          combinedData[curr] !== 0)
    );

    const activityValues = currencies.map((curr) =>
      Math.abs(activityData[curr] || 0)
    );
    const passivityValues = currencies.map((curr) =>
      Math.abs(passivityData[curr] || 0)
    );

    // Calculate the maximum value from combined data for the common base
    const maxCombinedValue = Math.max(
      ...currencies.map((curr) => Math.abs(combinedData[curr] || 0))
    );

    const activityChartData = {
      labels: currencies,
      datasets: [
        {
          axis: "y",
          label: "Activity",
          data: activityValues,
          fill: false,
          backgroundColor: "rgba(87, 182, 87, 0.5)",
          borderColor: "rgba(87, 182, 87, 0.5)",
          borderWidth: 1,
        },
      ],
    };

    const passivityChartData = {
      labels: currencies,
      datasets: [
        {
          axis: "y",
          label: "Passivity",
          data: passivityValues,
          fill: false,
          backgroundColor: "rgba(255, 71, 71, 0.5)",
          borderColor: "rgba(255, 71, 71, 0.5)",
          borderWidth: 1,
        },
      ],
    };

    const commonOptions = {
      indexAxis: "y",
      scales: {
        x: {
          min: 0,
          max: maxCombinedValue,
          ticks: {
            callback: function (value) {
              return Math.abs(value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          display: false,
        },
        y: {
          ticks: {
            font: {
              weight: "bold",
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: "#000",
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: function (value) {
            return Math.abs(value).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
      },
    };

    // Activity chart specific options
    const activityOptions = {
      ...commonOptions,
      scales: {
        ...commonOptions.scales,
        x: {
          ...commonOptions.scales.x,
          reverse: true,
        },
        y: {
          ...commonOptions.scales.y,
          position: "right",
        },
      },
      plugins: {
        ...commonOptions.plugins,
        datalabels: {
          ...commonOptions.plugins.datalabels,
          anchor: "end",
          align: "end",
          offset: -48,
        },
      },
    };

    // Passivity chart specific options
    const passivityOptions = {
      ...commonOptions,
      plugins: {
        ...commonOptions.plugins,
        datalabels: {
          ...commonOptions.plugins.datalabels,
          anchor: "start",
          align: "start",
          offset: -48,
        },
      },
    };

    chartInstancesRef.current.activity = new Chart(activityCtx, {
      type: "bar",
      data: activityChartData,
      options: activityOptions,
    });

    chartInstancesRef.current.passivity = new Chart(passivityCtx, {
      type: "bar",
      data: passivityChartData,
      options: passivityOptions,
    });

    return () => {
      if (chartInstancesRef.current.activity) {
        chartInstancesRef.current.activity.destroy();
      }
      if (chartInstancesRef.current.passivity) {
        chartInstancesRef.current.passivity.destroy();
      }
    };
  }, [activityData, passivityData, combinedData]);

  // Calculate the differences between activity and passivity values for each currency
  const differences = Object.keys(activityData || {})
    .filter(
      (curr) =>
        curr !== "grand_total" &&
        (activityData[curr] !== 0 ||
          passivityData[curr] !== 0 ||
          combinedData[curr] !== 0)
    )
    .map((curr) => ({
      currency: curr,
      difference: (activityData[curr] || 0) - (passivityData[curr] || 0),
    }));

  return (
    <div className="card">
      <div className="card-body">
        <h6 className="color fs-16 mb-0">Prospetto di Bilancio</h6>
        <div className="d-flex col-12">
          <div className="d-flex col-5 justify-content-end align-items-center text-success">
            <i className="fa-solid fa-arrow-trend-up mr-2"></i>
            Activity{" "}
            {activityData?.grand_total
              ? `(${formatAmount(activityData.grand_total, "EUR")})`
              : ""}
          </div>
          <div className="d-flex col-2 justify-content-center">Balance</div>
          <div className="d-flex col-5 align-items-center text-danger">
            <i className="fa-solid fa-arrow-trend-down mr-2"></i>
            Passivity{" "}
            {passivityData?.grand_total
              ? `(${formatAmount(passivityData.grand_total, "EUR")})`
              : ""}
          </div>
        </div>
        <div className="d-flex col-12">
          <div className="d-flex col-5 justify-content-end">
            <canvas
              id="activity-bar-chart"
              ref={activityChartRef}
              className="chartjs-render-monitor"
              style={{ display: "block", width: "100%", height: 250 }}
            />
          </div>
          <div className="d-flex flex-column col-2 justify-content-center align-items-center">
            {differences.map(({ currency, difference }, index) => (
              <div
                key={currency}
                className="p-3 text-center"
                style={{
                  height: `${250 / differences.length}px`, // Adjust height to match the bar height
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {formatAmount(difference, currency)}
              </div>
            ))}
          </div>
          <div className="d-flex col-5">
            <canvas
              id="passivity-bar-chart"
              ref={passivityChartRef}
              className="chartjs-render-monitor"
              style={{ display: "block", width: "100%", height: 250 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
