import { APIBaseURL } from "../Environment/Environment";

const API_URL = {
  ADMIN_LOGIN: APIBaseURL + "login",
  Forget_Password: APIBaseURL + "admin/forgot_password",
  RESET_Password: APIBaseURL + "admin/reset_password",
  CHANGE_PASSWORD: APIBaseURL + "admin/changePassword",
  ADMIN_DETAILS: APIBaseURL + "getUserDetails",
  UPDATE_ADMIN_DETAILS: APIBaseURL + "updateDetail",

  //group
  GROUP_LISTING: APIBaseURL + "getGroups",
  GROUP_LISTING_WITHOUT_PAGINATION:
    APIBaseURL + "getGroupsWithoutPaginationList",
  CREATE_GROUP: APIBaseURL + "addGroups",
  CHANGE_STATUS_GROUP: APIBaseURL + "changeStatusGroups",
  UPDATE_GROUP: APIBaseURL + "updateGroups",
  GROUP_DETAIL: APIBaseURL + "groupsSingleDetails",
  GROUP_DELETE: APIBaseURL + "deleteGroup",
  AMOUNT_CONVERSION: APIBaseURL + "currency-converter",
  UPDATE_REFERENT_PROFILE_ID: APIBaseURL + "updateProfileReferentId",

  // Group Balance
  GROUP_Balance_LISTING: APIBaseURL + "group_balance",

  //player
  PLAYERS_LISTING: APIBaseURL + "getPlayers",
  PLAYERS_LISTING_WITHOUT_PAGINATION:
    APIBaseURL + "getPlayersWithoutPaginationList",
  CREATE_PLAYER: APIBaseURL + "addPlayers",
  CHANGE_STATUS_PLAYER: APIBaseURL + "changeStatusPlayers",
  UPDATE_PLAYERS: APIBaseURL + "updatePlayers",
  PLAYER_DETAILS: APIBaseURL + "playersSingleDetails",

  // collaborators
  COLLABORATORS_LISTING: APIBaseURL + "getCollaborates",
  COLLABORATORS_LISTING_WITHOUT_PAGINATION:
    APIBaseURL + "getCollaborateWithoutPaginationList",
  CREATE_COLLABORATORS: APIBaseURL + "addCollaborates",
  CHANGE_STATUS_COLLABORATORS: APIBaseURL + "changeStatusCollaborates",
  UPDATE_COLLABORATORS: APIBaseURL + "updateCollaborates",
  COLLABORATORS_DETAILS: APIBaseURL + "CollaboratesSingleDetails",

  //reference
  GET_REFERENCE: APIBaseURL + "getReferents",
  CREATE_REFERENCE: APIBaseURL + "addReferents",
  CHANGE_STATUS_REFERENCE: APIBaseURL + "changeStatusReferents",
  UPDATE_REFERENCE: APIBaseURL + "updateReferents",
  REFERENTS_LISTING_WITHOUT_PAGINATION:
    APIBaseURL + "getReferentsWithoutPaginationList",
  REFERENTS_DETAILS: APIBaseURL + "getReferentsSingleDetails",
  GET_REFERENT_LISTING: APIBaseURL + "referentForGroups",

  //profiles
  GET_PROFILO: APIBaseURL + "getProfile",
  CREATE_PROFILO: APIBaseURL + "addProfile",
  CHANGE_STATUS_PROFILO: APIBaseURL + "changeStatusProfile",
  UPDATE_PROFILO: APIBaseURL + "updateProfile",
  GET_CURRENCY: APIBaseURL + "getCurrencies",
  PROFILES_LISTING_WITHOUT_PAGINATION:
    APIBaseURL + "getContractisWithoutPaginationList",
  PROFILO_DETAILS: APIBaseURL + "ProfileSingleDetails",
  REFERENT_PROFILE_WITH_GROUP_DETAILS:
    APIBaseURL + "referentProfileWithGroupDetails",

  //SLIP
  SLIP_LISTING: APIBaseURL + "getSlips",
  CREATE_SLIP: APIBaseURL + "createSlip",
  SLIP_DETAIL: APIBaseURL + "getSlipsbyId",
  GET_SPORTS: APIBaseURL + "getSports",
  GET_MARKETS: APIBaseURL + "getMarkets",
  GET_SIGN_OR_SELECTION_LIST: APIBaseURL + "getSelections",
  CHANGE_STATUS_SLIP: APIBaseURL + "changeStatusSlip",
  DELETE_SLIP: APIBaseURL + "deleteSlip",
  GET_CONNECTED_GROUP_OR_WALLET: APIBaseURL + "grouplistbyrefrentsAndPlayer",
  GET_SEARCH_EVENT_LIST: APIBaseURL + "getPrograms",
  GET_UPDATED_SLIP_STATUS: APIBaseURL + "slipStatusChange",

  //WALLET
  CREATE_WALLET: APIBaseURL + "createWalletHistory",
  UPDATE_WALLET: APIBaseURL + "updateWalletHistory",
  GET_WALLET: APIBaseURL + "getWallet",
  CHANGE_STATUS_WALLET: APIBaseURL + "",
  WALLET_DETAIL: APIBaseURL + "getWalletById",

  //TRANSACTION
  TRANSACTION_LISTING: APIBaseURL + "get-transaction",
  create_transaction: APIBaseURL + "create-transaction",

  //TRANSACTION_LOGS
  TRANSACTION_LOGS_LISTING: APIBaseURL + "get-transaction-logs",

  update_transaction: APIBaseURL + "edit-transaction",
  delete_transaction: APIBaseURL + "delete-transaction",

  // TAG
  TAG_LISTING: APIBaseURL + "get-tag-list",
  CREATE_TAG: APIBaseURL + "create-tag",
  UPDATE_TAG: APIBaseURL + "update-tag",
  CHANGE_TAG_STATUS: APIBaseURL + "change-tag-status",
  TAG_LIST_WITHOUT_PAGINATION: APIBaseURL + "get-tag-list-without-pagination",
  DELETE_TAG: APIBaseURL + "tag-delete",
};

export { API_URL };
